import React from 'react'
import styles from './LivestreamThumbnail.module.css'
import classNames from 'classnames'
import { PlayCircleIcon } from '@/atoms/Icons/PlayCircleIcon'
import { InternalLink } from '@/atoms/InternalLink'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { TitleAndSubtitleDisplay } from '@/atoms/TitleAndSubtitleDisplay'
import { paths } from '@/constants/paths'
import { LivestreamThumbnailPill } from '@/molecules/LivestreamThumbnailPill'
import { LivestreamTitle } from '@/page/LivestreamLandscapeRowCollection/LivestreamTitle'

interface CurrentLivestreamThumbnailProps {
  live?: boolean
  guid: string
  bannerCloudinaryPath: string
  startAt: string
  isEnded: boolean
  className?: string
  animationClassName?: string
  aspectRatioClassName?: string
  name?: string
  unavailableReason?: string
}

export const LivestreamThumbnail: React.FC<CurrentLivestreamThumbnailProps> = ({
  live = false,
  guid,
  bannerCloudinaryPath,
  startAt,
  isEnded,
  className,
  aspectRatioClassName = 'aspect-w-16 aspect-h-9',
  animationClassName = 'duration-[400ms] ease-in-out',
  name,
  unavailableReason,
}) => {
  if (typeof guid === 'undefined' || guid === null || !bannerCloudinaryPath) return null

  return (
    <InternalLink href={`${paths.livestream.index}/${guid}`}>
      <div
        className={classNames(
          `relative rounded-lg overflow-hidden cursor-pointer`,
          { 'border-red-400 border-4': live },
          styles.currentLivestreamThumbnail,
          aspectRatioClassName,
          animationClassName,
          className,
        )}
        style={{
          boxShadow: 'rgb(0 0 0 / 40%) 0px 24px 32px -8px, rgb(0 0 0 / 40%) 0px 12px 12px -8px',
        }}
      >
        <div className="-z-5 absolute h-full w-full">
          <Image
            alt={`${name} Livestream Thumbnail`}
            src={bannerCloudinaryPath}
            className="m-auto h-fit"
            layout="fill"
            objectFit="cover"
          />
        </div>
        <div className="absolute inset-0">
          <div
            className={classNames(
              'w-full h-full flex justify-center items-center opacity-0 transition',
              animationClassName,
              styles.playCircleOverlay,
            )}
          >
            <div className="mx-auto h-fit w-fit !opacity-100">
              <PlayCircleIcon />
            </div>
          </div>
          <div className="absolute bottom-2 right-2">
            <LivestreamThumbnailPill
              live={Boolean(live)}
              isEnded={isEnded}
              startAt={startAt}
              isGuildOnly={unavailableReason === 'PRERELEASE'}
            />
          </div>
        </div>
      </div>
      <div className="max-w-[320px] pt-2">
        <TitleAndSubtitleDisplay className="mr-4" title={''} subtitle={name} />
        <LivestreamTitle live={live} isEnded={isEnded} startAt={startAt} />
      </div>
    </InternalLink>
  )
}
