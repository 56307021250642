import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useExperiment } from '@optimizely/react-sdk'
import { useRouter } from 'next/router'
import { Unless } from 'react-if'
import {
  ANGEL_GUILD_BASIC_MONTHLY_OFFER_ID,
  ANGEL_GUILD_PREMIUM_MONTHLY_OFFER_ID,
  ANGEL_GUILD_PREMIUM_YEARLY_OFFER_ID,
} from '@/constants/offer-ids'
import { Modal } from '@/molecules/Modal'
import { createUseContextHook } from '@/molecules/Modal/ModalContextFactory'
import { GuildEarlyAccessModalBody } from '@/organisms/GuildEarlyAccessModal/GuildEarlyAccessModalBody'
import { GuildEarlyAccessModalHeader } from '@/organisms/GuildEarlyAccessModal/GuildEarlyAccessModalHeader'
import { GuildEarlyAccessModalLoginFooter } from '@/organisms/GuildEarlyAccessModal/GuildEarlyAccessModalLoginFooter'
import { Promotion } from '@/services/GuildPromotionService'
import { useGuildUser } from '@/services/GuildUserService'
import { Episode } from '@/services/ProjectsService'
import { useUser } from '@/services/UserService'
import { ReactFCC } from '@/types/react'

type GuildPlan = {
  planId: string
  price: number
  currency: string
  planType: 'premium' | 'basic'
}
interface GuildEarlyAccessModalContextValues {
  isModalOpen: boolean
  closeModal: () => void
  openModal: () => void
  doesUserHaveEarlyAccess: boolean
  loading: boolean
  isGuildMemberContent: boolean
  promotion: Promotion | null | undefined
  setSelectedPlan: (plan: GuildPlan) => void
  selectedPlan: GuildPlan
  yearlyPlan: GuildPlan
  monthlyPremiumPlan: GuildPlan
  monthlyBasicPlan: GuildPlan
}

interface Props {
  episode: Episode | undefined | null
  projectSlug?: string
  promotion?: Promotion | null
}

const GuildEarlyAccessModalContext = createContext<GuildEarlyAccessModalContextValues | null>(null)

export const useGuildEarlyAccessModalContext = createUseContextHook(GuildEarlyAccessModalContext)

const DEFAULT_YEARLY_PLAN: GuildPlan = {
  planId: ANGEL_GUILD_PREMIUM_YEARLY_OFFER_ID,
  price: 17900,
  currency: 'USD',
  planType: 'premium',
}

const DEFAULT_MONTHLY_PREMIUM_PLAN: GuildPlan = {
  price: 2000,
  currency: 'USD',
  planId: ANGEL_GUILD_PREMIUM_MONTHLY_OFFER_ID,
  planType: 'premium',
} as GuildPlan

const DEFAULT_MONTHLY_BASIC_PLAN: GuildPlan = {
  price: 1200,
  currency: 'USD',
  planId: ANGEL_GUILD_BASIC_MONTHLY_OFFER_ID,
  planType: 'basic',
}

export const GuildEarlyAccessModal: ReactFCC<Props> = ({ children, projectSlug, episode, promotion }) => {
  const router = useRouter()
  const { loading, isLoggedIn } = useUser()
  const [isModalOpen, setIsModalOpen] = useState(() => router?.asPath?.includes('#early-access'))
  const { guildRoles } = useGuildUser()

  //Experiment to reference guild roles hasEarlyAccess for guild tiers
  const experiment = useExperiment('guild_membership_tiers_experiment')
  const experimentOn = experiment[0] === 'on'
  const hasEarlyAccess = Boolean(guildRoles.hasEarlyAccess)
  const [selectedPlan, setSelectedPlan] = useState<GuildPlan>(DEFAULT_MONTHLY_PREMIUM_PLAN)

  const { isGuildMemberContent, doesUserHaveEarlyAccess, episodeLoading } = useMemo(() => {
    return {
      episodeLoading: episode === undefined,
      isGuildMemberContent: !!episode?.prereleaseAvailableFor?.includes('GUILD'),
      doesUserHaveEarlyAccess: experimentOn ? hasEarlyAccess : Boolean(episode?.currentUserHasEarlyAccess),
    }
  }, [episode, experimentOn, hasEarlyAccess])

  useEffect(() => {
    const hash = (router.asPath as string).split('#')[1]
    if (hash === 'earlyAccess') {
      setIsModalOpen(true)
    }
  }, [router.asPath])

  const closeModal = useCallback(() => {
    return setIsModalOpen(false)
  }, [])

  const openModal = useCallback(() => {
    return setIsModalOpen(true)
  }, [])

  const value = useMemo(
    () => ({
      isModalOpen: !episodeLoading && isModalOpen,
      doesUserHaveEarlyAccess,
      loading,
      isGuildMemberContent,
      promotion,
      closeModal,
      openModal,
      setSelectedPlan,
      selectedPlan,
      monthlyPremiumPlan: DEFAULT_MONTHLY_PREMIUM_PLAN,
      monthlyBasicPlan: DEFAULT_MONTHLY_BASIC_PLAN,
      yearlyPlan: DEFAULT_YEARLY_PLAN,
    }),
    [
      episodeLoading,
      isModalOpen,
      doesUserHaveEarlyAccess,
      loading,
      setSelectedPlan,
      selectedPlan,
      isGuildMemberContent,
      promotion,
      closeModal,
      openModal,
    ],
  )

  return (
    <GuildEarlyAccessModalContext.Provider value={value}>
      {children}
      <Modal
        className="z-50"
        isOpen={value.isModalOpen}
        onClose={value.closeModal}
        transition="appear"
        panelClassesOverride={`inline-block overflow-hidden transition-all transform w-full sm:w-fit sm:align-middle fixed inset-x-0 bottom-0 sm:static`}
      >
        <div className="w-full overflow-hidden rounded-t-2xl bg-white p-4 sm:w-[500px] sm:rounded-2xl">
          <GuildEarlyAccessModalHeader onClose={value.closeModal} />

          <Unless condition={value.doesUserHaveEarlyAccess}>
            <GuildEarlyAccessModalBody onClose={value.closeModal} projectSlug={projectSlug} />
          </Unless>

          <Unless condition={isLoggedIn}>
            <GuildEarlyAccessModalLoginFooter />
          </Unless>
        </div>
      </Modal>
    </GuildEarlyAccessModalContext.Provider>
  )
}
