import React, { useContext } from 'react'
import { reportErrorToBugsnag } from '@/utils/bugsnag'

export function createUseContextHook<T>(context: React.Context<T>) {
  return () => {
    const values = useContext<T>(context)
    if (values === null) {
      reportErrorToBugsnag(
        'Context values are null! Please ensure that this Context usage is using the associated Provider!',
      )
    }
    return values
  }
}
