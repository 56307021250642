import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { FeatureAnnouncement, saveFeatureIntroductionDismissed } from '@/services/FeatureAnnouncementService'

export interface FeatureIntroductionProviderProps {
  children: ReactNode
  featureAnnouncement: FeatureAnnouncement
  projectSlug?: string
  projectName?: string
}

export interface FeatureAnnouncementContextProps {
  closeFeatureAnnouncement(): void
  featureAnnouncement: FeatureAnnouncement
  openFeatureAnnouncement(): void
  projectName?: string
  projectSlug?: string
  showFeatureAnnouncement: boolean
}

export const FeatureAnnouncementContext = createContext<FeatureAnnouncementContextProps>(
  {} as FeatureAnnouncementContextProps,
)

export const useFeatureAnnouncement = () => useContext(FeatureAnnouncementContext)

export const FeatureAnnouncementProvider = ({
  children,
  featureAnnouncement,
  projectName,
  projectSlug,
}: FeatureIntroductionProviderProps) => {
  const [showFeatureAnnouncement, setShowFeatureAnnouncement] = useState(true)

  const closeFeatureAnnouncement = useCallback(() => {
    setShowFeatureAnnouncement(false)
    saveFeatureIntroductionDismissed(featureAnnouncement.featureSlug, projectSlug ? { projectSlug } : undefined)
  }, [featureAnnouncement?.featureSlug, projectSlug])

  const openFeatureAnnouncement = useCallback(() => {
    setShowFeatureAnnouncement(true)
  }, [])

  const value = {
    closeFeatureAnnouncement,
    featureAnnouncement,
    openFeatureAnnouncement,
    projectName,
    projectSlug,
    showFeatureAnnouncement,
  }

  return <FeatureAnnouncementContext.Provider value={value}>{children}</FeatureAnnouncementContext.Provider>
}

FeatureAnnouncementContext.displayName = 'FeatureAnnouncementProvider'
