import React, { HTMLAttributes, MouseEventHandler, ReactNode, useEffect, useState } from 'react'
import { AccordionItem as AccordionRow } from '@szhsin/react-accordion'
import { ItemState } from '@szhsin/react-accordion/types/utils/constants'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { IconColor } from '@/atoms/utils'
import { useIsDarkMode } from '@/contexts/ThemeContext'
import { Maybe } from '@/types/codegen-contentful'
import { ReactFCC } from '@/types/react'
import { AccordionContent } from './AccordionContent'
import { AccordionTrigger } from './AccordionTrigger'

const getIconColor = (isDarkMode: boolean | undefined, iconColor?: IconColor) => {
  return iconColor || (isDarkMode ? 'gray-200' : 'gray-800')
}

const getLabelClassName = (isDarkMode: boolean | undefined, labelClassName?: string) => {
  return labelClassName || (isDarkMode ? 'text-gray-100' : 'text-gray-900')
}
interface AccordionItemProps extends HTMLAttributes<HTMLDivElement> {
  contentElementId?: string
  labelClassName?: string
  iconClassName?: string
  iconColor?: IconColor
  showBottomBorder?: boolean
  showTopBorder?: boolean
  shouldUseCaretIcon?: boolean
  defaultOpen?: boolean
  triggerClassName?: string
  borderColor?: string
  contentClassName?: string
  rowClassName?: string
  label?: Maybe<string> | ReactNode | undefined
  header?: ReactNode | ((props: ItemState) => ReactNode)
  onClick?: MouseEventHandler | undefined
  disabled?: boolean
  hashLink?: string | null
}
export const AccordionItem: ReactFCC<AccordionItemProps> = ({
  contentElementId,
  showBottomBorder,
  showTopBorder = false,
  iconColor,
  labelClassName,
  iconClassName,
  shouldUseCaretIcon,
  defaultOpen = false,
  triggerClassName,
  borderColor = 'gray-700',
  contentClassName,
  rowClassName,
  label = '',
  children,
  header,
  onClick,
  disabled,
  hashLink,
  className,
}) => {
  const isDarkMode = useIsDarkMode()
  const router = useRouter()
  const [isLoaded, setLoaded] = useState(false)
  const hash = (router.asPath as string).split('#')[1]
  const showOpen = defaultOpen
  const bottomBorderClass = showBottomBorder ? `border-b border-${borderColor} border-solid` : ''
  const topBorderClass = showTopBorder ? `border-t border-${borderColor} border-solid` : ''
  iconColor = getIconColor(isDarkMode, iconColor)
  labelClassName = getLabelClassName(isDarkMode, labelClassName)

  useEffect(() => {
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (hashLink === hash && showOpen) {
      const destination = document.getElementById(hashLink)
      if (isLoaded && destination) {
        setTimeout(() => {
          destination.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        }, 500)
      }
    }
  }, [showOpen, hashLink, hash, isLoaded])

  return isLoaded ? (
    <AccordionRow
      id={hashLink ?? contentElementId}
      initialEntered={showOpen}
      onClick={onClick}
      disabled={disabled}
      header={
        header || (
          <div className={classNames(topBorderClass, bottomBorderClass, rowClassName)}>
            <AccordionTrigger
              label={label}
              iconColor={iconColor}
              labelClassName={labelClassName}
              shouldUseCaretIcon={shouldUseCaretIcon}
              shouldDefaultOpen={showOpen}
              iconClassName={iconClassName}
              className={classNames('flex flex-row items-center justify-between', triggerClassName)}
            />
          </div>
        )
      }
      className={classNames(bottomBorderClass, className)}
    >
      <AccordionContent contentClassName={contentClassName}>{children}</AccordionContent>
    </AccordionRow>
  ) : null
}
