import { createContext, PropsWithChildren, ReactElement, useContext, useEffect, useMemo, useState } from 'react'
import { useExperiment } from '@optimizely/react-sdk'
import { useRouter } from 'next/router'
import { isCookieConsentDialogVisible } from '@/molecules/CookieManagement'
import { GetAppBanner } from '@/organisms/GetAppBanner'
import { AppBannerAnalyticsContext } from '@/organisms/GetAppBanner/types'
import { shouldShowAnnouncementToUser } from '@/services/FeatureAnnouncementService/FeatureAnnouncementService'
import { getUserAgent } from '@/services/UserAgentService'

export interface GetAppBannerContext extends PropsWithChildren<unknown> {
  deepLink: string
  bannerVisible: boolean
  isLoading: boolean
}

export type GetAppBannerProviderProps = PropsWithChildren<{
  deepLink: string
  projectSlug?: string
  context: AppBannerAnalyticsContext
}>

export type GetAppBannerProvider = (props: GetAppBannerProviderProps) => ReactElement<GetAppBannerContext>

export const GetAppBannerContext = createContext<GetAppBannerContext>({
  deepLink: '',
  bannerVisible: false,
  isLoading: true,
})

interface GetBannerVisibleProps {
  shouldShowAnnouncement: boolean
  variationKey?: string | null
  isMobile?: boolean
}

export const getBannerVisible = ({ shouldShowAnnouncement, isMobile }: GetBannerVisibleProps): boolean => {
  if (shouldShowAnnouncement) return false
  return Boolean(isMobile)
}

export const GetAppBannerProvider = ({
  children,
  deepLink,
  projectSlug,
  context,
}: GetAppBannerProviderProps): ReactElement<GetAppBannerContext> => {
  const { asPath, query } = useRouter()
  const [announcementModalVariation, isAnnounceFlagReady] = useExperiment('announcement_modal_flag')
  const [bannerVisible, setBannerVisible] = useState(false)

  const isLoadingFlags = !isAnnounceFlagReady

  useEffect(() => {
    if (isLoadingFlags) return
    const isAnnouncementFlagOn = announcementModalVariation === 'on'
    const shouldShowAnnouncement =
      isAnnouncementFlagOn && (typeof query.announce === 'string' || shouldShowAnnouncementToUser(projectSlug))

    const isBannerVisible = getBannerVisible({
      shouldShowAnnouncement,
      isMobile: getUserAgent(window.navigator.userAgent)?.isMobile,
    })

    if (isBannerVisible) {
      const timeout = setTimeout(() => {
        if (typeof document === 'undefined') return

        const isCookieConsentVisible = isCookieConsentDialogVisible()
        const isShowingUpdatedLegalDocsModal = Boolean(document.getElementById('updated-legal-docs-modal'))
        const hasDismissed = Boolean(sessionStorage.getItem('getAppBannerDismissed'))

        if (!isCookieConsentVisible && !isShowingUpdatedLegalDocsModal && !hasDismissed) {
          setBannerVisible(isBannerVisible)
        }
      }, 3000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isLoadingFlags, projectSlug, announcementModalVariation, asPath, isAnnounceFlagReady, query.announce])

  const value = useMemo(() => {
    return { bannerVisible, deepLink, isLoading: !isLoadingFlags }
  }, [bannerVisible, deepLink, isLoadingFlags])

  return (
    <GetAppBannerContext.Provider value={value}>
      {children}
      <GetAppBanner
        deepLink={deepLink}
        showBanner={bannerVisible}
        onClose={() => {
          sessionStorage.setItem('getAppBannerDismissed', 'true')
          setBannerVisible(false)
        }}
        analytics={{
          context,
          projectSlug,
        }}
      />
    </GetAppBannerContext.Provider>
  )
}

export const useGetAppBanner = (): GetAppBannerContext => {
  return useContext(GetAppBannerContext)
}
