import React from 'react'
import { JsonLd, JsonLdProps } from './JsonLd'
import { Article } from './types'

export interface ArticleJsonLdProps extends JsonLdProps, Article {}

export const ArticleJsonLd: React.FC<ArticleJsonLdProps> = ({
  type = 'Article',
  keyOverride,
  ...rest
}: ArticleJsonLdProps) => {
  return <JsonLd type={type} keyOverride={keyOverride} {...rest} scriptKey="Article" />
}
