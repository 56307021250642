import React, { useMemo } from 'react'
import { TextProps } from '@/atoms/Text'
import { paths } from '@/constants'
import { ANGEL_GUILD_PREMIUM_MONTHLY_OFFER_ID, ANGEL_GUILD_PREMIUM_YEARLY_OFFER_ID } from '@/constants/offer-ids'
import { AppFeatures } from '@/experimentation'
import { ComingSoonNode } from '@/services/ComingSoonService'
import { CatalogTitle } from '@/services/ContentCatalog'
import { Promotion } from '@/services/GuildPromotionService'
import { GetGuildTheatricalReleasesResult } from '@/services/GuildReleasesService/TheatricalReleaseService'
import { GuildEarlyAccessSectionModel, GuildProjectSlugs, GuildSignupEmphasis } from '@/services/GuildSignupPageService'
import { GuildSignupLayout } from '@/services/GuildSignupPageService/GuildSignupPageTypes'
import { isGuildSignupSimpleLayout } from '@/services/GuildSignupPageService/validations'
import { NowStreamingContent } from '@/services/NowStreamingService'
import { GuildSale } from '@/services/SaleService'
import { Faq, GuildSignupExplainerLayout, GuildTestimonialSection } from '@/types/codegen-contentful'
import { ReactFCC } from '@/types/react'
import { GuildMovieTicketRelease } from '@/views/GuildDashboardView/GuildMovieTicketsPanel/types'
import { GuildCTAButtonProps } from '@/views/GuildSignupView/GuildCTAButton/GuildCTAButton'

interface GuildSignupContextProviderValues {
  catalogTitle?: CatalogTitle
  emphasis?: GuildSignupEmphasis
  guildApprovedTorchPosters?: string[]
  guildExplainerLayout?: GuildSignupExplainerLayout | null
  guildFaqs?: Faq[]
  guildMemberCta?: string
  guildMemberHref?: string
  layout?: GuildSignupLayout
  monthlyPlan: GuildPlan
  projectSlugs?: GuildProjectSlugs
  promotion: Promotion | null
  sale: GuildSale | null
  slug?: string
  theatricalReleases?: GetGuildTheatricalReleasesResult
  yearlyPlan: GuildPlan
  votingPhoneImageUrl?: string | null
  guildTestimonialSection?: GuildTestimonialSection
  guildEarlyAccessSection?: GuildEarlyAccessSectionModel
  movieTicketReleases?: GuildMovieTicketRelease[]
  guildCtaProps: GuildCTAButtonProps
  logo?: {
    src: string
    alt: string
    className?: string
  }
  experiments: Record<keyof AppFeatures, unknown>
  upcomingProjects?: ComingSoonNode[] | null
  nowStreamingProjects?: NowStreamingContent[] | null
}

interface GuildPlan {
  offerId: string
  price: number
  currency: string
}

const GuildSignupContext = React.createContext<GuildSignupContextProviderValues>({} as GuildSignupContextProviderValues)

export interface GuildSignupContextProviderProps {
  catalogTitle?: CatalogTitle
  emphasis?: GuildSignupEmphasis
  guildApprovedTorchPosters?: string[]
  guildExplainerLayout?: GuildSignupExplainerLayout | null
  guildFaqs?: Faq[]
  guildMemberCta?: string
  guildMemberHref?: string
  layout?: GuildSignupLayout
  projectSlugs?: GuildProjectSlugs
  promotion?: Promotion | null
  sale?: GuildSale | null
  slug?: string
  theatricalReleases?: GetGuildTheatricalReleasesResult
  votingPhoneImageUrl?: string | null
  guildTestimonialSection?: GuildTestimonialSection
  guildEarlyAccessSection?: GuildEarlyAccessSectionModel
  movieTicketReleases?: GuildMovieTicketRelease[]
  canShowAuth?: boolean
  logo?: {
    src: string
    alt: string
    className?: string
  }
  experiments?: Record<string, unknown>
  upcomingProjects?: ComingSoonNode[] | null
  nowStreamingProjects?: NowStreamingContent[] | null
}

const DEFAULT_MONTHLY_PLAN = {
  price: 2000,
  currency: 'USD',
  offerId: ANGEL_GUILD_PREMIUM_MONTHLY_OFFER_ID,
} as GuildPlan

const DEFAULT_YEARLY_PLAN = {
  price: 17900,
  currency: 'USD',
  offerId: ANGEL_GUILD_PREMIUM_YEARLY_OFFER_ID,
} as GuildPlan

export const GuildSignupContextProvider: ReactFCC<GuildSignupContextProviderProps> = ({
  catalogTitle,
  children,
  emphasis = 'early-access',
  guildApprovedTorchPosters = [],
  guildExplainerLayout,
  guildFaqs = [],
  guildMemberCta = 'Guild Member Benefits',
  guildMemberHref = paths.account.guild,
  layout,
  projectSlugs,
  promotion = null,
  sale = null,
  slug,
  theatricalReleases = [],
  votingPhoneImageUrl,
  guildTestimonialSection,
  guildEarlyAccessSection,
  movieTicketReleases,
  logo,
  experiments = {},
  upcomingProjects,
  nowStreamingProjects,
}) => {
  const guildCtaProps = useMemo(() => {
    return isGuildSignupSimpleLayout(layout)
      ? {
          hideIcon: true,
          buttonColorClassNames: '!w-fit !border-warm-03 !bg-warm-03',
          labelWeight: 'bold' as TextProps['weight'],
        }
      : ({} as Record<string, unknown>)
  }, [layout])

  const value = useMemo(() => {
    return {
      catalogTitle,
      emphasis,
      guildApprovedTorchPosters,
      guildExplainerLayout,
      guildFaqs,
      guildMemberCta,
      guildMemberHref,
      layout,
      monthlyPlan: DEFAULT_MONTHLY_PLAN,
      projectSlugs,
      promotion,
      sale,
      slug,
      theatricalReleases,
      yearlyPlan: DEFAULT_YEARLY_PLAN,
      votingPhoneImageUrl,
      guildTestimonialSection,
      guildEarlyAccessSection,
      movieTicketReleases,
      guildCtaProps,
      logo,
      experiments,
      upcomingProjects,
      nowStreamingProjects,
    }
  }, [
    catalogTitle,
    emphasis,
    guildApprovedTorchPosters,
    guildExplainerLayout,
    guildFaqs,
    guildMemberCta,
    guildMemberHref,
    layout,
    projectSlugs,
    promotion,
    sale,
    slug,
    theatricalReleases,
    votingPhoneImageUrl,
    guildTestimonialSection,
    guildEarlyAccessSection,
    movieTicketReleases,
    guildCtaProps,
    logo,
    experiments,
    upcomingProjects,
    nowStreamingProjects,
  ])

  return <GuildSignupContext.Provider value={value}>{children}</GuildSignupContext.Provider>
}

export const useGuildSignupContext = () => {
  return React.useContext(GuildSignupContext)
}

// TODO: this could come from a backend someday, but right now the backend is blocked by Auth
export function getGuildApprovedTorchPosters() {
  return Promise.resolve([
    'v1652137331/angel-app/for-your-consideration/TGK_Short_Poster_02',
    'v1666297265/angel-app/guild/torches/Tempest_poster',
    //CABRINI
    'v1722893901/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes_7',
    //SIGHT
    'v1722892302/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes',
    //SOUND OF HOPE
    'v1722892372/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes_1',
    //YOUNG DAVID
    'v1698961310/studio-app/catalog/ce1a0a75-cea7-48fa-96e4-e1fedeb27c69',
    //HOS
    'v1722893790/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes_6',
    //THE SHIFT
    'v1722893972/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes_8',
    //SOF
    'v1722892415/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes_2',
    'v1665676292/angel-app/guild/torches/guild_Axiom_key-art_eng',
    'v1652468593/angel-app/for-your-consideration/David_16x9_KeyArt_en',
    // DRY BAR
    'v1680734237/angel-studios/blog/DBC-Splash2_Landscape-2732x2048',
    //HOMESTEAD
    'v1722893661/angel-studios/guild/photon/cca9fc68-8b84-4568-9a60-55c06319b681',
    'v1668541848/angel-app/for-your-consideration/Livestreams/Truth-and-Conviction/11.15.22.Truth.UPDATED',
    'v1695657198/angel-app/guild/torches/after_death_thumbnail',
    'v1652909278/angel-app/for-your-consideration/Testament_Poster_Horiz_16x9',
    'v1722898802/angel-studios/guild/photon/gatg_new_image_cleanup',
    //WFS
    'v1722892617/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes_5',
    //TT
    'v1722892534/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes_3',
  ])
}
