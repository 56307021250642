import { RefObject, useEffect } from 'react'

interface useFadeBackgroundOnScrollProps {
  backgroundRef: RefObject<HTMLDivElement>
  boundaryRef: RefObject<HTMLDivElement>
}

export const useFadeBackgroundOnScroll = ({ backgroundRef, boundaryRef }: useFadeBackgroundOnScrollProps): void => {
  useEffect(() => {
    const topOfEpisodesSection = boundaryRef?.current?.offsetTop ?? 600
    const onScroll = () => {
      if (window.scrollY > 0) {
        const opacity = 1 - window.scrollY / topOfEpisodesSection

        if (backgroundRef?.current?.style) {
          backgroundRef.current.style.opacity = opacity <= 0.0 ? '0.0' : opacity + ''
        }
      } else {
        if (backgroundRef?.current?.style) {
          backgroundRef.current.style.opacity = '1'
        }
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [backgroundRef, boundaryRef])
}
