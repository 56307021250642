import React from 'react'
import classNames from 'classnames'
import { InternalLink } from '@/atoms/InternalLink'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { AsH3, TitleLG } from '@/atoms/Text'
import { Paragraph } from '@/atoms/Typography'
import { BasicCarousel } from '@/molecules/BasicCarousel'

export type ConnectedThumbnailCarouselOnClickArgs = {
  position: number
}

export interface ConnectedThumbnailCarouselProps {
  heading: string
  subHeading?: string
  cloudinaryImagePaths: string[]
  href: string
  priority?: boolean
  onClick?: (args: ConnectedThumbnailCarouselOnClickArgs) => void
  padding?: boolean
}

export const ConnectedThumbnailCarousel: React.FC<ConnectedThumbnailCarouselProps> = ({
  cloudinaryImagePaths,
  heading,
  subHeading,
  href,
  priority,
  onClick,
  padding,
}) => {
  return (
    <>
      <header>
        <TitleLG
          data-testid="project-tiles-header"
          weight="bold"
          as={AsH3}
          className={classNames('mb-3 md:mb-4', { 'px-4 sm:px-8 md:px-12 xl:px-16': padding })}
        >
          {heading}
          {subHeading && (
            <Paragraph as={(p) => <sub {...p} />} color="text-gray-300" className="block" lineHeight="leading-tight">
              {subHeading}
            </Paragraph>
          )}
        </TitleLG>
      </header>
      <BasicCarousel containerClassName={classNames({ 'px-4 sm:px-8 md:px-12 xl:px-16': padding })}>
        {cloudinaryImagePaths.map((path, i) => {
          return (
            <div
              className="w-2/3 min-w-[256px] max-w-[300px] shrink-0 cursor-pointer snap-center snap-always sm:w-2/5 lg:w-3/5"
              key={path}
              onClick={() => onClick?.({ position: i })}
            >
              <InternalLink href={href}>
                <div className="relative w-full overflow-hidden rounded-lg bg-black duration-[500ms] ease-in-out hover:opacity-70">
                  <Image
                    priority={priority}
                    alt={heading}
                    src={`/ar_16:9,c_thumb,q_auto,g_north,w_480${path}.webp`}
                    width={480}
                    height={270}
                    layout="responsive"
                  />
                </div>
              </InternalLink>
            </div>
          )
        })}
      </BasicCarousel>
    </>
  )
}
