/* eslint-disable @next/next/no-img-element */
import React from 'react'
import classNames from 'classnames'

interface StoryProfileImageProps {
  className?: string
  profileImage?: string
  authorLocation?: string
}

export const StoryProfileImage: React.VFC<StoryProfileImageProps> = (props) => {
  if (props.profileImage) {
    return (
      <div
        className={classNames([
          'flex flex-column items-center justify-center bg-gray-700 rounded-[18px] w-[36px] h-[36px]',
          props.className,
        ])}
      >
        <img
          data-testid="profile-image"
          width={32}
          height={32}
          src={props.profileImage}
          alt="User Profile Image"
          className="m-auto h-8 w-8 rounded-full object-cover"
        />
      </div>
    )
  }

  if (props.authorLocation && props.authorLocation.toLowerCase() !== 'n/a') {
    return (
      <div
        className={classNames([
          'flex flex-column items-center justify-center bg-gray-700 rounded-[18px] w-[36px] h-[36px] overflow-hidden',
          props.className,
        ])}
      >
        <div
          data-testid="country-flag-profile-image"
          className={'h-[32px] w-[32px] overflow-hidden rounded-[16px]'}
          style={{
            backgroundImage: `url(https://flagcdn.com/${props.authorLocation.toLocaleLowerCase()}.svg)`,
            backgroundSize: '64px 32px',
            backgroundPosition: 'center',
          }}
        />
      </div>
    )
  }

  return null
}
