import React, { FC, HTMLAttributes, ReactNode } from 'react'
import { Else, If, Then } from 'react-if'
import { CaptionLG } from '@/atoms/Text'

interface AccordionContentProps extends HTMLAttributes<HTMLElement> {
  children?: string | null | ReactNode | undefined
  contentClassName?: string
}

export const AccordionContent: FC<AccordionContentProps> = ({ children, contentClassName }) => {
  const isTextOnly = typeof children === 'string'

  return (
    <div className="w-full pb-4">
      <If condition={isTextOnly}>
        <Then>
          <CaptionLG className={contentClassName}>{children}</CaptionLG>
        </Then>
        <Else>{children}</Else>
      </If>
    </div>
  )
}
