import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const PlusIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'white', className }) => {
  const hexColor = getHexColor(color)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.75 12H20.25" stroke={hexColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 3.75V20.25" stroke={hexColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
