import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const WatchTriangleIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'black', className }) => {
  return (
    <svg
      data-testid="watch-triangle-icon"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={getHexColor(color)}
        d="M0.000309374 11.9572V1.99018C0.000309374 0.473904 1.64114 -0.486179 2.97243 0.256232L11.7629 5.27054L20.4301 10.2548C21.7614 11.0287 21.7614 12.9476 20.4301 13.7214L11.7325 18.6741L2.97212 23.6584C1.64083 24.4323 0 23.4722 0 21.9245L0.000309374 11.9572Z"
      />
    </svg>
  )
}
