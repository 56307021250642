import { random } from 'lodash'
import { Note, ThankYouNote, UserStory } from '@/services/StoriesService'

export function transformStory(story: UserStory, projectSlug: string): Note {
  const note = {
    projectSlug: projectSlug,
    id: story.id,
    publishedDate: new Date(parseInt(story.publishDate)).toISOString(),
    text: story.text || '',
    totalLikes: story.totalLikes,
    isLikedByUser: story.isLikedByUser || false,
    authorLocation: story.authorLocation,
    user: story.user
      ? {
          image: story.user.image,
          id: story.user.id,
          firstName: story.user.firstName,
        }
      : undefined,
    video: story.videoUri
      ? {
          id: story.id,
          guid: story.guid,
          name: story.title,
          title: story.title,
          subtitle: story.subtitle,
          slug: story.videoUri,
          posterCloudinaryPath: story.posterCloudinaryPath,
          source: {
            url: story.videoUri,
            duration: story.duration || 0,
          },
        }
      : undefined,
    momentId: story.momentId || undefined,
    momentTimeCode: story.momentTimeCode || undefined,
    isVip: story.isVip || false,
  }

  return note
}

export function transformThankYou(story: ThankYouNote, projectSlug: string): Note {
  const note = {
    projectSlug: projectSlug,
    id: story.id,
    publishedDate: story.publishDate,
    text: story.thankYouNote,
    totalLikes: story.totalLikes,
    isLikedByUser: story.likedByMe || false,
    authorLocation: story.authorLocation,
    user: story.userProfile
      ? {
          image: story.userProfile.image,
          id: story.userProfile.id,
          firstName: story.userProfile.firstName,
          country: story.userProfile.country,
        }
      : undefined,
    video: story.video
      ? {
          id: story.video.id,
          guid: story.video.guid,
          name: story.video.name,
          title: story.video.title,
          subtitle: story.video.subtitle,
          slug: story.video.slug,
          posterCloudinaryPath: story.video.posterCloudinaryPath,
          source: {
            url: story.video.source.url,
            duration: story.video.source.duration || 0,
          },
        }
      : undefined,
  }

  return note
}

export function transformStories(stories: UserStory[], projectSlug: string): Note[] {
  const notes = stories?.map((story) => {
    const note = transformStory(story, projectSlug)

    return note
  })

  return notes
}

export function transformThankYous(thankYouNotes: ThankYouNote[], projectSlug: string): Note[] {
  const notes = thankYouNotes?.map((story) => {
    const note = transformThankYou(story, projectSlug)

    return note
  })

  return notes
}

export function prepareCommunityNotes(thankYouNotes: Note[], storyNotes: Note[]): Note[] {
  let thankYouIdx = 0
  let storyIdx = 0
  const mergedNotes: Note[] = []

  while (thankYouIdx < thankYouNotes?.length || storyIdx < storyNotes?.length) {
    const thankYousToAdd = random(1, 3)
    const storiesToAdd = 1

    let thankYousAdded = 0
    let storiesAdded = 0

    while (storiesAdded < storiesToAdd && storyIdx < storyNotes.length) {
      const storyNote = storyNotes[storyIdx]
      const isDuplicate = filterDuplicateNotes(mergedNotes, storyNote)
      if (!isDuplicate) {
        mergedNotes.push(storyNote)
        storiesAdded++
      }
      storyIdx++
    }

    while (thankYousAdded < thankYousToAdd && thankYouIdx < thankYouNotes.length) {
      const thankYouNote = thankYouNotes[thankYouIdx]
      const isDuplicate = filterDuplicateNotes(mergedNotes, thankYouNote)
      if (!isDuplicate) {
        mergedNotes.push(thankYouNote)
        thankYousAdded++
      }
      thankYouIdx++
    }
  }

  return mergedNotes
}

function filterDuplicateNotes(mergedNotes: Note[], note: Note): boolean {
  return mergedNotes.some((existingNote) => existingNote.video?.source?.url === note.video?.source?.url)
}
