import { reportWarningToBugsnag } from '../bugsnag'

export function getDisplayCountry(country: string, locale = 'en') {
  try {
    const englishRegions = new Intl.DisplayNames([locale], { type: 'region' })

    return englishRegions.of(country)
  } catch (e) {
    if (e instanceof Error) {
      reportWarningToBugsnag(e)
    }
  }
}
