import React, { useContext, useEffect } from 'react'
import { CaretLeftIcon } from '@/atoms/Icons/CaretLeftIcon'
import { InternalLink } from '@/atoms/InternalLink'
import { paths } from '@/constants'
import { UserStory, ThankYouNote } from '@/services/StoriesService'
import { useTranslate } from '@/utils/translate/translate-client'
import { EmptyStoryCard } from '@/views/WatchProjectView/CommunityView/EmptyStoryCard'
import { StoryCard } from '@/views/WatchProjectView/CommunityView/StoryCard'
import { WatchProjectContext } from '@/views/WatchProjectView/WatchProjectContext'
import { transformStory, transformThankYou } from '../StoryTransfomations'

interface SingleStoryProps {
  story?: ThankYouNote | UserStory
}

export const SingleStory: React.FC<SingleStoryProps> = ({ story }) => {
  const { projectTheme } = useContext(WatchProjectContext)

  const { t } = useTranslate('watch')

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  let transformedStory

  if (story && story.__typename === 'ThankYouFeed') {
    transformedStory = transformThankYou(story as ThankYouNote, projectTheme.slug)
  } else if (story && story.__typename === 'UserStory') {
    transformedStory = transformStory(story as UserStory, projectTheme.slug)
  }

  let renderedStory

  if (!story && transformedStory) {
    renderedStory = <EmptyStoryCard className="animate-pulse" />
  } else {
    renderedStory = <StoryCard story={transformedStory} className="rounded-t-none" />
  }

  return (
    <div id="single-story" className="relative">
      <div className="fixed left-0 top-0 z-10 mx-auto h-full w-full overflow-y-auto overflow-x-hidden">
        <div className="absolute inset-0 w-screen bg-black/75 " />
        <div className="absolute left-0 top-0 w-full px-8 pt-20">
          <InternalLink
            style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
            href={`${paths.watch.index}/${projectTheme.slug}#community`}
            data-testid="return-to-community-link"
            className="flex flex-row items-center bg-black p-4 text-sm font-semibold text-white focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 focus-visible:ring-offset-transparent"
          >
            <CaretLeftIcon className="mr-3" /> {t('backToCommunity', 'Back to Community')}
          </InternalLink>
          {renderedStory}
        </div>
      </div>
    </div>
  )
}
