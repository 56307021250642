import React from 'react'
import { JsonLd, JsonLdProps } from './JsonLd'
import { TVSeries } from './types'

export interface TVSeriesJsonLdProps extends JsonLdProps, TVSeries {}

export const TVSeriesJsonLd: React.FC<TVSeriesJsonLdProps> = ({
  type = 'TVSeries',
  keyOverride,
  ...rest
}: TVSeriesJsonLdProps) => <JsonLd type={type} keyOverride={keyOverride} {...rest} scriptKey="TVSeries" />
