import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Episode, getProjectQuery, Project, Season } from '@/services/ProjectsService'
import { getProjectTheme } from '@/utils/project-themes'
import { ProjectTheme } from '@/utils/project-themes/types'
import { useWatchProjectData } from '@/views/WatchProjectView/useWatchProjectData'

interface UseWatchProjectProps {
  projectSlug: string
  skip?: boolean
  isGuildMember?: boolean
}

export const useWatchProject = ({
  projectSlug,
  skip = false,
  isGuildMember = false,
}: UseWatchProjectProps): {
  preferredSeason?: Season
  currentEpisode?: Episode
  project?: Project
  projectTheme: ProjectTheme
  loading: boolean
} => {
  const [project, setProject] = useState<Project>()

  const { data, loading } = useQuery(getProjectQuery, {
    variables: { slug: projectSlug, includePrerelease: true, isGuildMember },
  })
  const { currentEpisode, preferredSeason } = useWatchProjectData({ data: data?.project, skip })
  const projectTheme = getProjectTheme(projectSlug) ?? {}

  useEffect(() => {
    setProject(data?.project)
  }, [data])

  return {
    currentEpisode,
    preferredSeason,
    project,
    projectTheme,
    loading,
  }
}
