import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { BasicCarousel } from '@/molecules/BasicCarousel'
import { LivestreamThumbnail } from '@/molecules/LivestreamThumbnail'
import { PlaceholderMediaCard } from '@/molecules/MediaCard'
import { Livestream } from '../Livestream'

interface Livestream {
  live: boolean
  id: number
  projectSlug: string
  guid: string
  name: string
  bannerCloudinaryPath: string
  startAt: string
  isEnded: boolean
  unavailableReason?: string
}

interface HorizontalMediaScrollerProps extends PropsWithChildren<unknown> {
  livestreams?: Livestream[]
  onThumbnailClick?: (livestream: Livestream) => void
  scrollerClassName?: string
  padding?: boolean
}

export const HorizontalLivestreamScroller: React.FC<HorizontalMediaScrollerProps> = ({
  livestreams,
  padding = false,
}) => {
  const hasLivestreams = Boolean(livestreams?.length)

  return (
    <BasicCarousel containerClassName={classNames({ 'px-4 sm:px-8 md:px-12 xl:px-16': padding })}>
      {hasLivestreams &&
        livestreams?.map?.((livestream) => {
          return (
            <div
              className="relative w-2/3 min-w-[256px] max-w-[300px] shrink-0 snap-center snap-always sm:w-2/5 lg:w-3/5"
              key={livestream.guid}
            >
              <LivestreamThumbnail className="mb-3" {...livestream} />
            </div>
          )
        })}
      {!hasLivestreams &&
        Array(5)
          .fill(0)
          .map((v, i) => (
            <PlaceholderMediaCard className="mr-4" borderRadiusClassName="rounded-lg" hideTextPlaceholders key={i} />
          ))}
    </BasicCarousel>
  )
}
