const watchEpisodeMatcher = /\/watch\/([^/\\\s]+)\/episode\/([^/\\\s#]+)(?:#.+)?/
const livestreamMatcher = /\/livestreams\/([^/\\\s#]+)(?:#.+)?/
const videoMatcher = /\/watch\/([^/\\\s]+)\/videos\/([^/\\\s]+)\/([^/\\\s#]+)(?:#.+)?/
const storyMatcher = /\/watch\/([^/\\\s]+)\/community\/stories\/([^/\\\s#]+)(?:#.+)?/

export const isEpisodeRoute = (path: string): boolean => watchEpisodeMatcher.test(path)
export const getEpisodePropsFromPath = (path: string): { projectSlug: string; episodeGuid: string } | null => {
  const match = path.match(watchEpisodeMatcher)
  if (!match) return null
  const [_path, projectSlug, episodeGuid] = match

  return {
    projectSlug,
    episodeGuid,
  }
}

export const isLivestreamRoute = (path: string): boolean => livestreamMatcher.test(path)
export const getLivestreamPropsFromPath = (path: string): { livestreamId: string } | null => {
  const match = path.match(livestreamMatcher)
  if (!match) return null
  const [_path, livestreamId] = match

  return {
    livestreamId,
  }
}

export const isVideoRoute = (path: string): boolean => videoMatcher.test(path)
export const getVideoPropsFromPath = (path: string): { projectSlug: string; guid: string; page: string } | null => {
  const match = path.match(videoMatcher)
  if (!match) return null
  const [_path, projectSlug, page, guid] = match

  return {
    projectSlug,
    guid,
    page,
  }
}

export const isStoryRoute = (path: string): boolean => storyMatcher.test(path)
export const getStoryPropsFromPath = (path: string): { projectSlug: string; storyId: string } | null => {
  const match = path.match(storyMatcher)
  if (!match) return null
  const [_path, projectSlug, storyId] = match

  return {
    projectSlug,
    storyId,
  }
}

export const isPushToMobileRoute = (path: string) => {
  return isEpisodeRoute(path) || isLivestreamRoute(path) || isVideoRoute(path) || isStoryRoute(path)
}
