import React from 'react'
import { JsonLd, JsonLdProps } from './JsonLd'
import { TVEpisode } from './types'

interface TVEpisodeJsonLdProps extends JsonLdProps, TVEpisode {}

export const TVEpisodeJsonLd: React.FC<TVEpisodeJsonLdProps> = ({
  type = 'TVEpisode',
  keyOverride,
  ...rest
}: TVEpisodeJsonLdProps) => <JsonLd type={type} keyOverride={keyOverride} {...rest} scriptKey="TVEpisode" />
