import { InternalLink } from '@/atoms/InternalLink'
import { ParagraphSM } from '@/atoms/Text'
import { useLoginUrl } from '@/utils/auth-utils'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

export const GuildEarlyAccessModalLoginFooter: React.FC = () => {
  const { t } = useTranslate('watch')
  const { loginUrl } = useLoginUrl()

  return (
    <Translate t={t} i18nKey="guildAlreadyAMember">
      <ParagraphSM className="mt-4 inline-block text-center" color="core-gray-950" weight="normal">
        Already a member of the Guild?
      </ParagraphSM>
      <InternalLink className="inline-block cursor-pointer p-1" href={loginUrl}>
        <ParagraphSM weight="semibold" className="inline-block text-center" color="blue-500">
          Log In
        </ParagraphSM>
      </InternalLink>
    </Translate>
  )
}
