import React, { useMemo } from 'react'
import { If, Then } from 'react-if'
import { InternalLink } from '@/atoms/InternalLink'
import { useTranslate } from '@/utils/translate/translate-client'

export interface TextStoryProps {
  id: string
  abbreviated?: boolean
  className?: string
  thankYouNote: string
  href: string
}

export const TextStory: React.VFC<TextStoryProps> = (props) => {
  const isTruncated = props.abbreviated && props.thankYouNote.length >= 250

  const { t } = useTranslate('watch')

  const noteToShow = useMemo(() => {
    if (!isTruncated) {
      return props.thankYouNote
    }

    const numWordsToKeep = props.thankYouNote.substring(0, 200).split(' ').length
    const wordsToKeep = props.thankYouNote.split(' ').splice(0, numWordsToKeep)

    return wordsToKeep.join(' ') + '... '
  }, [isTruncated, props.thankYouNote])

  return (
    <div data-testid="story-text" className={props.className}>
      {noteToShow}
      <If condition={isTruncated}>
        <Then>
          <InternalLink href={props.href} scroll data-testid="read-more-button" className="text-oxide">
            {t('readMore', 'read more')}
          </InternalLink>
        </Then>
      </If>
    </div>
  )
}
