import { FC, useCallback, useEffect, useRef } from 'react'
import { Button } from '@/atoms/Button'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { EyebrowSM, HeadingXS } from '@/atoms/Text'
import { Modal } from '@/molecules/Modal'
import { useAngelPlayerContext } from '@/organisms/AngelPlayer'
import { DESCRIPTION_COMPONENTS } from '@/organisms/FeatureAnnouncementModal/FeatureDescriptions'
import { useSafeTrack } from '@/utils/analytics'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { useFeatureAnnouncement } from './FeatureAnnouncementProvider'

export const FeatureAnnouncementModalHolder: FC = () => {
  const { closeFeatureAnnouncement, featureAnnouncement, projectName, projectSlug, showFeatureAnnouncement } =
    useFeatureAnnouncement()
  const track = useSafeTrack()
  const hasTrackedAnnouncementRef = useRef<boolean>(false)
  const { t } = useTranslate('features')
  const { controller, playing } = useAngelPlayerContext()
  const DescriptionComponent = featureAnnouncement ? DESCRIPTION_COMPONENTS[featureAnnouncement?.featureSlug] : null

  useEffect(() => {
    if (showFeatureAnnouncement) {
      if (!hasTrackedAnnouncementRef.current) {
        track('Feature Announcement Viewed', { featureSlug: featureAnnouncement?.featureSlug, projectSlug })
      }

      if (playing) {
        controller?.pause()
      }
    }
  }, [track, controller, featureAnnouncement?.featureSlug, projectSlug, playing, showFeatureAnnouncement])

  const handleOKClick = useCallback(() => {
    track('Feature Announcement Dismissed', { featureSlug: featureAnnouncement?.featureSlug, projectSlug })
    controller?.play()

    closeFeatureAnnouncement()
  }, [featureAnnouncement?.featureSlug, projectSlug, track, closeFeatureAnnouncement, controller])

  return showFeatureAnnouncement && featureAnnouncement ? (
    <Modal className="z-[9999] mx-6" isOpen={showFeatureAnnouncement} onClose={() => null}>
      <div className="flex flex-col items-center space-y-4 py-1 md:space-y-6 md:py-4">
        {featureAnnouncement.heroImageUrl ? (
          <Image alt="new feature" width={240} height={145} layout="intrinsic" src={featureAnnouncement.heroImageUrl} />
        ) : null}
        <div className="flex flex-col items-center space-y-1">
          <EyebrowSM color="gray-600" className="md:photon-eyebrow-md">
            <Translate t={t} i18nKey="newFeature">
              NEW FEATURE
            </Translate>
          </EyebrowSM>
          <HeadingXS className="md:photon-heading-lg lg:photon-heading-sm" weight="bold">
            {featureAnnouncement.title}
          </HeadingXS>
        </div>
        {DescriptionComponent ? <DescriptionComponent projectName={projectName} /> : null}
        <div className="flex justify-center md:py-3 lg:py-4">
          <Button
            onClick={handleOKClick}
            className="border border-solid !border-gray-950 text-gray-950"
            variant="white"
          >
            <Translate t={t} i18nKey="ok">
              OK
            </Translate>
          </Button>
        </div>
      </div>
    </Modal>
  ) : null
}
