import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import Bugsnag from '@bugsnag/js'
import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase'
import { Duration } from 'luxon'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import { Button } from '@/atoms/Button'
import { CircleInfoIcon } from '@/atoms/Icons/CircleInfoIcon'
import { FlagIcon } from '@/atoms/Icons/FlagIcon'
import { HeartIcon } from '@/atoms/Icons/HeartIcon'
import { ShareIcon } from '@/atoms/Icons/ShareIcon'
import { TwitterXIcon } from '@/atoms/Icons/TwitterXIcon'
import { InternalLink } from '@/atoms/InternalLink'
import { paths } from '@/constants'
import { publisher } from '@/constants/seo'
import { VideoObjectJsonLd } from '@/layout/Seo/JsonLd'
import { AngelDialog } from '@/molecules/AngelDialog'
import { useToast } from '@/molecules/Toast'
import { SmpteController } from '@/organisms/AngelPlayer'
import { Note } from '@/services/StoriesService'
import { getAuthorDisplay } from '@/services/StoriesService/StoriesService'
import { useReportUserStoryById } from '@/services/StoriesService/hooks'
import { useTranslate } from '@/utils/translate/translate-client'
import { StoryHeader } from '@/views/WatchProjectView/CommunityView/StoryCard/StoryHeader'
import { TextStory } from '@/views/WatchProjectView/CommunityView/StoryCard/TextStory'
import { VideoStory } from '@/views/WatchProjectView/CommunityView/StoryCard/VideoStory'
import { WatchProjectContext } from '@/views/WatchProjectView/WatchProjectContext'

export const createThankYouLikeMutation = gql`
  mutation CreateThankYouLike($input: ThankYouLikeInput!) {
    createThankYouLike(input: $input) {
      like {
        id
      }
    }
  }
`

export const deleteThankYouLikeMutation = gql`
  mutation deleteThankYouLike($input: ThankYouLikeInput!) {
    deleteThankYouLike(input: $input) {
      feed {
        id
        totalLikes
      }
    }
  }
`

export interface StoryCardProps {
  story?: Note
  abbreviated?: boolean
  className?: string
}

export function shareTitle({ project, viewerName }: { project?: string; viewerName: string }) {
  return `Come and see how ${project} has impacted ${viewerName}`
}

export const StoryCard: React.FC<StoryCardProps> = ({ story, abbreviated, className }) => {
  const { id, publishedDate, text, totalLikes, isLikedByUser, authorLocation, user, video } = story as Note
  const storyId = id

  const [isShareOpen, setShareOpen] = useState(false)
  const [isShareHovered, setShareHovered] = useState(false)
  const [showCopied, setShowCopied] = useState(-1)
  const [canShowReportStory, setCanShowReportStory] = useState(false)
  const { projectTheme } = useContext(WatchProjectContext)
  const [likedByMe, setLikedByMe] = useState(isLikedByUser)
  const [totalLikesCount, setTotalLikesCount] = useState(totalLikes)
  const [smtpeController, setSmtpeController] = useState<SmpteController | undefined>()
  const [createLike] = useMutation(createThankYouLikeMutation)
  const [deleteLike] = useMutation(deleteThankYouLikeMutation)
  const { reportUserStoryById } = useReportUserStoryById()
  const { showToast } = useToast()

  const storyLink = `/watch/${projectTheme.slug}/community/stories/${storyId}`
  const fullClientStoryLink = typeof window !== 'undefined' ? window.location.origin + storyLink : null

  const { t } = useTranslate('watch')

  const title = useMemo(() => {
    const { viewerName } = getAuthorDisplay({
      defaultViewerName: t('aViewer', 'a viewer'),
      firstName: user?.firstName,
      country: user?.country,
      authorLocation: authorLocation,
    })

    return shareTitle({ project: projectTheme.slug, viewerName })
  }, [t, user?.firstName, user?.country, authorLocation, projectTheme.slug])

  const onCopy = useCallback(async () => {
    if (fullClientStoryLink) {
      await navigator.clipboard.writeText(fullClientStoryLink)
      setShowCopied((copied) => {
        if (copied > 0) {
          clearTimeout(copied)
        }
        return window.setTimeout(() => setShowCopied(-1), 750)
      })
    }
  }, [fullClientStoryLink])

  useEffect(() => () => window.clearTimeout(showCopied), [showCopied])

  const handleLike = async () => {
    try {
      if (!likedByMe) {
        await createLike({ variables: { input: { thankYouId: storyId } } })
        setTotalLikesCount(totalLikesCount + 1)
      } else {
        await deleteLike({ variables: { input: { thankYouId: storyId } } })
        setTotalLikesCount(totalLikesCount - 1)
      }
      setLikedByMe(!likedByMe)
    } catch (e) {
      if (e instanceof Error) {
        Bugsnag.notify(e)
      }
    }
  }

  const handleShowReportStoryOverlay = useCallback(() => {
    setCanShowReportStory(true)
    smtpeController?.pause()
  }, [smtpeController])

  const handleHideReportStoryOverlay = useCallback(() => {
    setCanShowReportStory(false)
    smtpeController?.play()
  }, [smtpeController])

  const handleReportingStoryClicked = useCallback(async () => {
    await reportUserStoryById(storyId)
    showToast(t('thankYouForReporting', 'Thank you. This story has been reported.'), { shouldHideClose: true })
    setCanShowReportStory(false)
  }, [reportUserStoryById, showToast, storyId, t])

  const handleControllerInit = useCallback((smtpeController: SmpteController) => {
    setSmtpeController(smtpeController)
  }, [])

  let storyDisplay

  if (video?.source?.url) {
    storyDisplay = (
      <>
        {video.name && (
          <VideoObjectJsonLd
            contentUrl={video.source.url}
            description={video.subtitle}
            duration={Duration.fromDurationLike({ hours: 0, minutes: 0, seconds: video.source.duration })
              .normalize()
              .toISO()}
            interactionStatistic={{
              type: 'InteractionCounter',
              interactionType: { type: 'LikeAction' },
              userInteractionCount: totalLikes,
            }}
            keyOverride={`VideoObject-${kebabCase(video.name)}`}
            name={video.name}
            publisher={publisher}
            thumbnailUrl={`https://images.angelstudios.com/image/upload/${video.posterCloudinaryPath}.webp`}
            uploadDate={publishedDate}
            url={`${paths.base}${storyLink}`}
          />
        )}
        <VideoStory
          id={storyId}
          projectSlug={projectTheme.slug}
          video={video}
          href={storyLink}
          abbreviated={abbreviated}
          className="flex flex-1 flex-col"
          onControllerInit={handleControllerInit}
        />
      </>
    )
  } else if (text) {
    storyDisplay = (
      <TextStory
        id={storyId}
        thankYouNote={text}
        href={storyLink}
        abbreviated={abbreviated}
        className="mb-6 overflow-x-clip"
      />
    )
  }

  return (
    <div aria-label="story-card" id={storyId} className="relative">
      {canShowReportStory && (
        <div onClick={handleHideReportStoryOverlay} className="absolute inset-0 z-50 flex bg-black/[65%]">
          <div className="flex h-full w-full items-center justify-center">
            <Button
              onClick={handleReportingStoryClicked}
              variant="white"
              className="flex items-center gap-x-1 shadow-dark-1"
            >
              <CircleInfoIcon size={20} color="black" />
              Report This Story
            </Button>
          </div>
        </div>
      )}
      <div className={classNames('gap-y-2 flex flex-col rounded-lg bg-black px-8 pb-8', className)}>
        <StoryHeader
          className="mb-3"
          publishDate={publishedDate}
          authorLocation={authorLocation}
          profileImage={user?.image}
          country={user?.country}
          firstName={user?.firstName}
        />
        {storyDisplay}
        {story?.momentId && (
          <Button variant="oxide" className="my-3">
            <InternalLink href={`/share/moment/${story.momentId}`}>
              {t('goToThisMoment', 'Go to this Moment')}
            </InternalLink>
          </Button>
        )}
        <div className="flex flex-row items-center font-light text-gray-400">
          <Button onClick={handleLike} aria-label="like-story-toggle" variant="plain">
            <HeartIcon color1="gray-400" color2={likedByMe ? 'gray-400' : 'transparent'} className="mr-2" />
          </Button>
          <span aria-label="total-likes" className="mr-6">
            {totalLikesCount}
          </span>
          <div
            aria-label="share-link"
            className="mr-2 flex cursor-pointer flex-row items-center hover:text-white"
            onClick={() => setShareOpen(true)}
            onMouseEnter={() => setShareHovered(true)}
            onMouseLeave={() => setShareHovered(false)}
          >
            <ShareIcon size={18} color={isShareHovered ? 'white' : 'gray-400'} className="mr-2" />
            <span>{t('share', 'Share')}</span>
          </div>
          <Button
            aria-label="report-user-story"
            className="ml-2"
            onClick={handleShowReportStoryOverlay}
            variant="plain"
          >
            <FlagIcon size={24} color="gray-400" />
          </Button>
        </div>
        {fullClientStoryLink && (
          <AngelDialog isDarkMode open={isShareOpen} onClose={() => setShareOpen(false)} title={t('share', 'Share')}>
            <div className="flex flex-row justify-around p-3 pb-10">
              <FacebookShareButton aria-label="facebook-share-button" url={fullClientStoryLink} title={title}>
                <FacebookIcon className="rounded-lg hover:scale-110" size={64} />
              </FacebookShareButton>
              <TwitterShareButton aria-label="twitter-share-button" url={fullClientStoryLink} title={title}>
                <TwitterXIcon className="rounded-lg hover:scale-110" size={64} />
              </TwitterShareButton>
              <LinkedinShareButton aria-label="linkedin-share-button" url={fullClientStoryLink}>
                <LinkedinIcon className="rounded-lg hover:scale-110" size={64} />
              </LinkedinShareButton>
              <EmailShareButton aria-label="email-share-button" url={fullClientStoryLink} subject={title}>
                <EmailIcon className="rounded-lg hover:scale-110" size={64} />
              </EmailShareButton>
            </div>
            <div className="flex flex-row border border-white/30 bg-black/50 p-3">
              <input
                aria-label="copy-text"
                readOnly
                autoFocus={false}
                tabIndex={-1}
                className="mr-6 w-60 bg-transparent focus:border-oxide focus:outline-none focus:ring-1 focus:ring-oxide sm:w-80 md:w-96"
                value={showCopied > 0 ? t('copied', 'Copied!') + '🎉' : fullClientStoryLink}
              />
              <span
                aria-label="copy-text-button"
                className="ml-auto cursor-pointer uppercase text-oxide hover:scale-110 hover:text-oxide-light"
                onClick={onCopy}
              >
                {t('copy', 'Copy')}
              </span>
            </div>
          </AngelDialog>
        )}
      </div>
    </div>
  )
}
