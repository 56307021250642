import React, { HTMLAttributes, useCallback } from 'react'
import { Tab } from '@headlessui/react'
import { ParagraphLG } from '@/atoms/Typography'
import { ReactFCC } from '@/types/react'

export const DepartmentTabList: ReactFCC = (props) => {
  const { children } = props

  return (
    <Tab.List className="flex w-full flex-row items-stretch bg-core-gray-950 px-4 pt-4 sm:px-8 md:px-12 xl:px-16">
      <div className="w-full border-b border-white/30">{children}</div>
    </Tab.List>
  )
}

interface TabHeaderProps extends HTMLAttributes<unknown> {
  hidden?: boolean
  className?: string
}

export const DepartmentTabHeader: ReactFCC<TabHeaderProps> = (props) => {
  const { children, className = '', ...rest } = props
  const classNameCreator = useCallback(
    ({ selected }: { selected: boolean }) => {
      const conditionalClasses = selected ? 'border-b-4 border-oxide selected' : 'border-b-4 border-transparent'

      return `department-tab text-left mr-8 focus-visible:!ring-transparent focus-visible:!ring-offset-transparent focus-visible:!outline-none whitespace-nowrap scroll-mt-24 ${className} ${conditionalClasses}`
    },
    [className],
  )

  return (
    <Tab hidden={props.hidden} className={classNameCreator} role="tab" {...rest}>
      {({ selected }) => {
        return (
          <ParagraphLG
            className="cursor-pointer pb-4"
            fontWeight={selected ? 'font-bold' : undefined}
            color={selected ? 'text-white' : 'text-white/70'}
          >
            {children}
          </ParagraphLG>
        )
      }}
    </Tab>
  )
}

export const DepartmentTab: ReactFCC = (props) => {
  const { children } = props

  return (
    <Tab.Panel className="mt-8 focus:border-none focus:outline-none" unmount={false}>
      {children}
    </Tab.Panel>
  )
}
