import { useEffect, useState, useMemo } from 'react'
import { QueryResult } from '@apollo/client'
import { useRouter } from 'next/router'
import { Episode, Season, getPreferredSeason, Project } from '@/services/ProjectsService'
import { getSeasonByNameOrIndex } from '@/services/ProjectsService/ProjectsService'
import { isASneakPeek } from '@/utils/EpisodeUtil'
import { getItemOrFirstEntry } from '@/utils/array-utils'

interface useWatchProjectDataProps {
  // TODO make this the correct type
  data: QueryResult['data']
  skip?: boolean
  isGuildMember?: boolean
}

//TODO Re-rendering more than 15 times - figure out
export const useWatchProjectData = ({ data, skip, isGuildMember = false }: useWatchProjectDataProps) => {
  const router = useRouter()

  const seasonNameOrIndex = getItemOrFirstEntry(router.query.season)
  const project = data
  const seasons = project?.seasons?.filter(Boolean) as Season[] | undefined
  const [preferredSeason, setPreferredSeason] = useState<Season>()

  useEffect(() => {
    if (!project || !router.isReady) {
      return
    }

    if (seasonNameOrIndex) {
      setPreferredSeason(getSeasonByNameOrIndex({ seasonNameOrIndex, project }))
    } else {
      const preferredSeason = getPreferredSeason(project)
      setPreferredSeason(preferredSeason)
    }
  }, [data, project, router, seasonNameOrIndex, seasons])

  let currentEpisode: Episode | undefined = undefined
  const lastWatched = project?.continueWatching?.guid

  if (lastWatched) {
    seasons?.forEach((season) => {
      season?.episodes?.forEach((episode) => {
        if (episode?.guid === lastWatched) {
          currentEpisode = episode
        }
      })
    })
  }

  if (!currentEpisode) {
    currentEpisode = returnDefaultEpisode(seasons, isGuildMember, project)
  }

  return useMemo(() => {
    if (skip) {
      return {
        data,
      }
    }

    return {
      data,
      preferredSeason,
      currentEpisode,
    }
  }, [skip, data, preferredSeason, currentEpisode])
}

function returnDefaultEpisode(seasons: Season[] | undefined, isGuildMember: boolean, project: Project) {
  const sneakPeek =
    !isGuildMember && project?.projectType === 'movie'
      ? seasons?.[0]?.episodes?.find((episode) => isASneakPeek(episode))
      : null
  return sneakPeek ?? seasons?.[0]?.episodes?.[0] ?? undefined
}
