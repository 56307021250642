import classNames from 'classnames'
import { When } from 'react-if'
import { AsH3, TitleLG } from '@/atoms/Text'
import { DocumentScroller } from '@/organisms/DocumentScroller'
import { Downloadable } from '@/services/DownloadablesService'
import { useUser } from '@/services/UserService'
import { useTranslate } from '@/utils/translate/translate-client'

export interface DownloadSectionProps {
  downloadables: Downloadable[]
  projectSlug: string
  padding?: boolean
}

export const DownloadSection: React.FC<DownloadSectionProps> = ({ downloadables, projectSlug, padding = false }) => {
  const { t } = useTranslate('watch')
  const { userId } = useUser()

  return (
    <When condition={downloadables.length > 0}>
      <TitleLG
        weight="bold"
        as={AsH3}
        className={classNames({ 'px-4 sm:px-8 md:px-12 xl:px-16 mb-3 md:mb-4 p-0': padding })}
      >
        {t('downloads', 'Downloads')}
      </TitleLG>
      <DocumentScroller
        authenticated={!!userId}
        downloadables={downloadables}
        project={projectSlug}
        returnTo={`/watch/${projectSlug}#downloadables`}
        padding={padding}
      />
    </When>
  )
}
