import React from 'react'
import { Case, Default, Switch } from 'react-if'
import { LinkButton } from '@/atoms/Button'
import { CircleCheckIcon } from '@/atoms/Icons/CircleCheckIcon'
import { EmptyCircleIcon } from '@/atoms/Icons/EmptyCircleIcon'
import { AsP, CaptionMD, EyebrowMD, LabelMD, ParagraphSM } from '@/atoms/Text'
import { paths } from '@/constants'
import { ANGEL_GUILD_PREMIUM_YEARLY_OFFER_ID } from '@/constants/offer-ids'
import { useGuildEarlyAccessModalContext } from '@/organisms/GuildEarlyAccessModal/GuildEarlyAccessModal'
import { useUser } from '@/services/UserService'
import { ReactFCC } from '@/types/react'
import { useLoginUrl } from '@/utils/auth-utils'
import { useTranslate } from '@/utils/translate/translate-client'
import { GuildPlanTypeOptions } from './GuildPlanTypeOptions'

interface GuildEarlyAccessModalProps {
  ctaPath?: string
  projectSlug?: string
  onClose: () => void
}

export const GuildEarlyAccessModalBody: React.FC<GuildEarlyAccessModalProps> = ({ projectSlug }) => {
  const { t } = useTranslate('watch')
  const context = useGuildEarlyAccessModalContext()
  const { isLoggedIn } = useUser()
  const shouldAppendPromoCode =
    context?.selectedPlan.planId === ANGEL_GUILD_PREMIUM_YEARLY_OFFER_ID && context.promotion?.promoCode

  const utms = `?utm_campaign=guild-early-access&utm_source=watch&utm_medium=web&utm_term=${projectSlug}${
    shouldAppendPromoCode ? `&promoCode=${context?.promotion?.promoCode}` : ''
  }`
  const guildHref = `${paths.guild.checkout}/${context?.selectedPlan?.planId}${utms}`

  const { loginUrl } = useLoginUrl(guildHref)
  const subscribeHref = isLoggedIn ? guildHref : loginUrl

  return (
    <div>
      <CaptionMD className="mt-1 text-center" weight="normal">
        {t('becomeAMemberToGainAccess', 'Become an Angel Guild Member to Gain Access!')}
      </CaptionMD>

      <GuildPlanTypeOptions />

      <EyebrowMD weight="bold" className="mb-1 mt-4 text-left sm:mt-12" as={AsP} color="core-gray-600">
        {t('guildMembershipBenefitsTitle', 'Your Guild Membership Will:')}
      </EyebrowMD>

      <CheckedRow checked>
        {t('guildHelpsFundAngelOriginals', 'Help fund Angel Originals, speeding up new content releases')}
      </CheckedRow>
      <CheckedRow checked>{t('guildAllowsYouToVoteV1', 'Allow you to vote on potential new projects')}</CheckedRow>
      <CheckedRow checked>
        {t('guildGetYouEarlyAccessToAngelOriginals', 'Give you early access to Angel Originals')}
      </CheckedRow>
      <CheckedRow checked={context?.selectedPlan?.planType === 'premium'}>
        {t('guildTickets', 'Include 2 tickets to each Angel theatrical release')}
      </CheckedRow>
      <CheckedRow checked={context?.selectedPlan?.planType === 'premium'}>
        {t('guildMerchDiscount', 'Grant 20% off on all Angel merch in the online store')}
      </CheckedRow>
      <LinkButton className="mb-2 w-full rounded-lg py-4 sm:mt-12" href={subscribeHref} variant={'black'}>
        <LabelMD className="mx-auto" weight="semibold">
          {t('joinTheGuildButton', 'Join the Guild')}
        </LabelMD>
      </LinkButton>
      <LinkButton className="w-full rounded-lg py-4" href={`${paths.guild.index}${utms}`} variant="black" outline>
        <LabelMD className="mx-auto" weight="semibold">
          {t('learnMore', 'Learn More')}
        </LabelMD>
      </LinkButton>
    </div>
  )
}

const CheckedRow: ReactFCC<{ checked?: boolean }> = ({ children, checked = true }) => {
  return (
    <div className="my-1 ml-1 flex flex-row items-center justify-start">
      <Switch>
        <Case condition={checked}>
          <CircleCheckIcon className="shrink-0" color1="black" color2="white" size={18} />
          <ParagraphSM className="ml-2 text-left" color="core-gray-950" weight="normal">
            {children}
          </ParagraphSM>
        </Case>
        <Default>
          <EmptyCircleIcon size={18} color1="core-gray-400" className="flex-none" />
          <ParagraphSM className="ml-2 text-left" color="core-gray-400" weight="normal">
            {children}
          </ParagraphSM>
        </Default>
      </Switch>
    </div>
  )
}
