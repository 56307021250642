import React, { FC, HTMLAttributes, useState, useCallback } from 'react'
import classNames from 'classnames'
import { Else, If, Then } from 'react-if'
import { CaretDownIcon } from '@/atoms/Icons/CaretDownIcon'
import { CaretUpIcon } from '@/atoms/Icons/CaretUpIcon'
import { MinusIcon } from '@/atoms/Icons/MinusIcon'
import { PlusIcon } from '@/atoms/Icons/PlusIcon'
import { CaptionLG } from '@/atoms/Text'
import { IconColor } from '@/atoms/utils'

interface AccordionTriggerProps extends HTMLAttributes<HTMLElement> {
  label?: string | React.ReactNode
  iconColor?: IconColor
  labelClassName?: string
  iconClassName?: string
  shouldUseCaretIcon?: boolean
  shouldDefaultOpen?: boolean
}

export const AccordionTrigger: FC<AccordionTriggerProps> = ({
  className,
  label,
  labelClassName,
  iconColor,
  shouldUseCaretIcon = false,
  shouldDefaultOpen,
  iconClassName,
}) => {
  const [open, setOpen] = useState(shouldDefaultOpen)

  const handleClick = useCallback(() => {
    setOpen(!open)
  }, [open])

  const isTextOnly = typeof label === 'string'

  return (
    <div onClick={handleClick} className={classNames('flex items-center justify-between py-4 text-left', className)}>
      <If condition={isTextOnly}>
        <Then>
          <CaptionLG weight="bold" className={classNames('w-full', labelClassName)}>
            {label}
          </CaptionLG>
        </Then>
        <Else>{label}</Else>
      </If>
      <If condition={open}>
        <Then>
          {shouldUseCaretIcon ? (
            <CaretUpIcon className={iconClassName} color={iconColor} />
          ) : (
            <MinusIcon className={iconClassName} color={iconColor} />
          )}
        </Then>
        <Else>
          {shouldUseCaretIcon ? (
            <CaretDownIcon className={iconClassName} color={iconColor} />
          ) : (
            <PlusIcon className={iconClassName} color={iconColor} />
          )}
        </Else>
      </If>
    </div>
  )
}
