import React, { HTMLAttributes } from 'react'
import classNames from 'classnames'

interface AboutSectionContainer extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  outerClassName?: string
}

export const AboutSectionContainer: React.FC<AboutSectionContainer> = ({ children, outerClassName, ...props }) => {
  return (
    <div
      className={classNames(
        'border border-t-0 border-l-0 border-r-0 border-b-1 border-white/10 py-10 lg:py-16',
        outerClassName,
      )}
    >
      <section {...props}>{children}</section>
    </div>
  )
}
