import isbot from 'isbot'

export interface UserAgent {
  isMobile: boolean
  isBot: boolean
}

/*
 I went down the path of using a library and found this article along the way: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
 Mozilla suggests checking the user-agent header for a "Mobi" value in the string to determine mobile devices, I added isBot to support detectings crawlers. For now this seemed like the simplest
 approach for what was needed. They definitely discourage user-agent sniffing unless you have certain needs.
*/
export function getUserAgent(userAgentHeader?: string | null): UserAgent {
  if (!userAgentHeader) {
    return {
      isMobile: false,
      isBot: false,
    }
  }

  const isBot = isbot(userAgentHeader)

  return {
    isMobile: userAgentHeader.includes('Mobi'),
    isBot,
  }
}
