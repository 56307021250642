import React from 'react'
import { Else, If, Then } from 'react-if'
import { ThankYouNote, UserStory } from '@/services/StoriesService'
import { SingleStory } from './SingleStory'
import { StoriesList } from './StoriesList'

export interface CommunityViewProps {
  singleStory?: boolean
  story?: ThankYouNote | UserStory
}

export const CommunityView: React.VFC<CommunityViewProps> = ({ singleStory = false, story }) => {
  return (
    <div className="mx-auto max-w-3xl">
      <div className="pb-10">
        <If condition={singleStory}>
          <Then>
            <SingleStory story={story} />
          </Then>
          <Else>
            <StoriesList />
          </Else>
        </If>
      </div>
    </div>
  )
}
