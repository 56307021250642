import React from 'react'
import classNames from 'classnames'
import { ExternalLink } from '@/atoms/ExternalLink'
import { DownloadIcon } from '@/atoms/Icons/DownloadIcon'
import { InternalLink } from '@/atoms/InternalLink'
import { CaptionSM, TitleXS } from '@/atoms/Text'
import { useLoginUrl } from '@/utils/auth-utils'
import { useTranslate } from '@/utils/translate/translate-client'

interface DocumentCardProps {
  authenticated: boolean
  href: string
  image: string
  onClick: () => void
  returnTo: string
  title?: string | null
  type?: string | null
  variant?: 'light' | 'dark'
}

export const DocumentCard: React.FC<DocumentCardProps> = ({
  authenticated,
  href,
  image,
  onClick,
  returnTo,
  title,
  type,
  variant = 'dark',
}) => {
  const { t } = useTranslate('common')
  const { loginUrl, trackLoginStarted } = useLoginUrl(returnTo)

  const card = (
    <div
      className={classNames(
        'rounded-2xl items-center group-downloadable box-border ease-out duration-[400ms] hover:shadow-xl hover:shadow-black/[.84]',
        {
          'bg-black hover:bg-white/[.1]': variant === 'dark',
          'bg-gray-100 hover:bg-black/[0.1]': variant === 'light',
        },
      )}
    >
      <div className="p-2">
        <div className="relative">
          <img
            className="h-[338px] w-[252px] rounded-2xl object-cover object-center"
            src={image}
            alt={`${title} ${type}`}
          />
          {authenticated ? (
            <div className="absolute inset-0 z-10 flex h-full items-center justify-center opacity-0 duration-[400ms] ease-out group-downloadable-hover:opacity-100">
              <DownloadIcon className="rounded-full border border-gray-300 bg-white p-3 drop-shadow-lg" size={56} />
            </div>
          ) : (
            <div className="absolute inset-0 z-10 flex h-full items-center justify-center opacity-0 duration-[400ms] ease-out group-downloadable-hover:opacity-100">
              <div className="rounded bg-oxide px-3 py-2">
                <CaptionSM color="black" weight="bold">
                  {t('loginToDownload', 'Login to Download')}
                </CaptionSM>
              </div>
            </div>
          )}
        </div>
        <div className="min-h-[80px] pt-4">
          <TitleXS color={variant === 'dark' ? 'white' : 'black'}>{title}</TitleXS>
          <TitleXS color="gray-600">{type}</TitleXS>
        </div>
      </div>
    </div>
  )

  return (
    <div className="w-[268px] shrink-0 snap-center snap-always">
      {authenticated ? (
        <ExternalLink download href={href} onClick={onClick}>
          {card}
        </ExternalLink>
      ) : (
        <InternalLink
          href={loginUrl}
          onClick={() => {
            trackLoginStarted('downloadable-document-card')
            onClick()
          }}
        >
          {card}
        </InternalLink>
      )}
    </div>
  )
}
