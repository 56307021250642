import { slugs } from '@/constants'
import { FeatureAnnouncement, FeatureSlug, FeatureIntroduction } from '@/services/FeatureAnnouncementService'
import { ANGEL_USER_FEATURE_INTRODUCTION, getObjectFromLocalStorage, writeToLocalStorage } from '@/utils/local-storage'

// TODO: revisit this - https://linear.app/angel-studios/issue/WEB-1090/determine-the-fate-of-the-feature-announcement-modal
// This was changed from two to zero to disable the modal because the modal was causing large bounce rates
const NUM_REQUIRED_USER_DISMISSALS = 0

const DEFAULT_FEATURE_INTRODUCTIONS_STORE: FeatureIntroductionStore = { introductions: [] }

export interface FeatureIntroductionStore {
  introductions: FeatureIntroduction[]
}

export interface FeatureIntroductionOptions {
  projectSlug?: string
}

// TODO change the input params of this function to take a single param --- introduction: Pick<FeatureIntroduction, 'featureSlug' | 'projectSlug'>
export function findFeatureIntroduction(featureSlug: FeatureSlug, options?: FeatureIntroductionOptions) {
  if (typeof window === 'undefined') {
    return
  }

  const userStore = getObjectFromLocalStorage(ANGEL_USER_FEATURE_INTRODUCTION, DEFAULT_FEATURE_INTRODUCTIONS_STORE)

  if (options?.projectSlug) {
    return userStore.introductions.find(
      (introduction) => introduction.featureSlug === featureSlug && introduction.projectSlug === options?.projectSlug,
    )
  } else {
    return userStore.introductions.find((introduction) => introduction.featureSlug === featureSlug)
  }
}

// TODO The featureSlug & projectSlug are stored on the introduction object. There should only be one input param --- introduction: FeatureIntroduction
export function upsertFeatureIntroduction(
  featureSlug: FeatureSlug,
  introduction: FeatureIntroduction,
  options?: FeatureIntroductionOptions,
) {
  if (typeof window === 'undefined') {
    return
  }
  const userStore = getObjectFromLocalStorage(ANGEL_USER_FEATURE_INTRODUCTION, DEFAULT_FEATURE_INTRODUCTIONS_STORE)

  const otherIntros = userStore.introductions.filter(
    (i) => i.featureSlug !== featureSlug && i.projectSlug !== options?.projectSlug,
  )
  const updated: FeatureIntroductionStore = { introductions: [...otherIntros, introduction] }

  writeToLocalStorage(ANGEL_USER_FEATURE_INTRODUCTION, updated)
}

// TODO change the input params of this function to take a single param --- introduction: Pick<FeatureIntroduction, 'featureSlug' | 'projectSlug'>
export function saveFeatureIntroductionDismissed(featureSlug: FeatureSlug, options?: FeatureIntroductionOptions) {
  const maybeIntro = findFeatureIntroduction(featureSlug, { projectSlug: options?.projectSlug })
  const introToUpsert = maybeIntro
    ? { ...maybeIntro, dismissedCount: maybeIntro.dismissedCount + 1 }
    : { featureSlug, projectSlug: options?.projectSlug, dismissedCount: 1 }

  upsertFeatureIntroduction(featureSlug, introToUpsert, options)
}

const FEATURE_ANNOUNCEMENTS: FeatureAnnouncement[] = [
  {
    featureSlug: 'express-your-love',
    projectSlugs: [slugs.theChosen],
    heroImageUrl: '/v1680533315/angel-app/assets/collectables/golden-scene-trophy-illustration-02.png',
    title: 'Express Your Love',
    ctaLabel: 'OK',
  },
]

export function shouldShowAnnouncementToUser(projectSlug?: string) {
  const announcement = getFeatureAnnouncement(projectSlug)

  if (!announcement) {
    return false
  }

  const introduction = findFeatureIntroduction(announcement.featureSlug, { projectSlug })
  return (introduction?.dismissedCount || 0) < NUM_REQUIRED_USER_DISMISSALS
}

export const getFeatureAnnouncement = (projectSlug?: string) => {
  return (
    FEATURE_ANNOUNCEMENTS.find(
      (announcement) => announcement.projectSlugs.length === 0 || announcement.projectSlugs.includes(projectSlug || ''),
    ) || null
  )
}
