import React, { useMemo } from 'react'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { When } from 'react-if'
import { CaptionSM, ParagraphSM } from '@/atoms/Text'
import { getDisplayCountry } from '@/utils/DisplayCountryUtil'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'
import { StoryProfileImage } from './StoryProfileImage'

const useFormattedPublishDate = (publishDate?: string) => {
  return useMemo(() => {
    if (!publishDate) {
      return null
    }

    const luxonDate = DateTime.fromISO(publishDate, { zone: 'local' })

    return luxonDate.toLocaleString({
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
  }, [publishDate])
}

export interface StoryHeaderProps {
  className?: string
  publishDate: string
  authorLocation: string
  firstName?: string
  country?: string
  profileImage?: string
}

export const StoryHeader: React.VFC<StoryHeaderProps> = (props) => {
  const { locale } = useLocale()
  const { t } = useTranslate('common')
  const viewerName = props.firstName || t('viewer', 'Viewer')
  const authorCountry = props.country || props.authorLocation
  let locationDisplay = ''

  if (authorCountry && authorCountry.length === 2) {
    locationDisplay = t('fromCountry', ' from {{country}}', { country: getDisplayCountry(authorCountry, locale) })
  }

  const publishDate = useFormattedPublishDate(props.publishDate)

  return (
    <div className={classNames(['flex flex-row items-center', props.className])}>
      <StoryProfileImage className="mr-4" profileImage={props.profileImage} authorLocation={props.authorLocation} />
      <div>
        <ParagraphSM weight="semibold" className="text-white">{`${viewerName}${locationDisplay}`}</ParagraphSM>
        <When condition={!!publishDate}>
          <CaptionSM color="gray-600">{publishDate}</CaptionSM>
        </When>
      </div>
    </div>
  )
}
