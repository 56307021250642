import React, { HTMLAttributes } from 'react'
import { Accordion as AccordionWrapper } from '@szhsin/react-accordion'
import { ItemKey } from '@szhsin/react-accordion/types/utils/constants'
import classNames from 'classnames'
import { TransitionState } from 'react-transition-state'
import { ReactFCC } from '@/types/react'

interface AccordionProps extends HTMLAttributes<HTMLDivElement> {
  showBottomBorder?: boolean
  onStateChange?: (event: { key: ItemKey; current: TransitionState }) => void
  className?: string
  borderColor?: string
  allowMultiple?: boolean
}

export const Accordion: ReactFCC<AccordionProps> = ({
  showBottomBorder,
  className,
  borderColor = 'gray-700',
  children,
  onStateChange,
  allowMultiple = true,
}) => {
  const bottomBorderClass = showBottomBorder ? `border-b border-${borderColor} border-solid` : ''

  return (
    <AccordionWrapper
      onStateChange={onStateChange}
      className={classNames('w-full', className, bottomBorderClass)}
      allowMultiple={allowMultiple}
      transition
      transitionTimeout={250}
    >
      {children}
    </AccordionWrapper>
  )
}
