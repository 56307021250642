import React, { FC } from 'react'
import { ParagraphMD } from '@/atoms/Text'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface ExpressYourLoveDescriptionProps {
  projectName?: string
}

export const ExpressYourLoveDescription: FC<ExpressYourLoveDescriptionProps> = ({ projectName }) => {
  const { t } = useTranslate('features')

  return projectName ? (
    <ParagraphMD className="md:photon-paragraph-lg text-center">
      <Translate t={t} i18nKey="loveYourFavoriteProjectSceneByReacting" values={{ projectName }}>
        Love your favorite scenes in {{ projectName }}; <b>PRESS the heart button</b> when watching an episode to react.
      </Translate>
    </ParagraphMD>
  ) : (
    <ParagraphMD className="md:photon-paragraph-lg text-center">
      <Translate t={t} i18nKey="loveYourFavoriteSceneByReacting">
        Love your favorite scenes; <b>PRESS the heart button</b> when watching an episode to react.
      </Translate>
    </ParagraphMD>
  )
}

export const DESCRIPTION_COMPONENTS = {
  'express-your-love': ExpressYourLoveDescription,
}
