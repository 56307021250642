import React from 'react'
import { JsonLd, JsonLdProps } from './JsonLd'
import { PodcastSeries } from './types'

interface PodcastSeriesJsonLdProps extends JsonLdProps, PodcastSeries {}

export const PodcastSeriesJsonLd: React.FC<PodcastSeriesJsonLdProps> = ({
  type = 'PodcastSeries',
  keyOverride,
  ...rest
}: PodcastSeriesJsonLdProps) => <JsonLd type={type} keyOverride={keyOverride} {...rest} scriptKey="PodcastSeries" />
