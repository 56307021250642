import React from 'react'
import { InView } from 'react-intersection-observer'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { ConnectedThumbnailCarousel } from '@/organisms/ConnectedThumbnailCarousel'
import { ConnectedCarousel } from '@/services/ProjectsService/types'
import { useSafeAnalytics } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

interface PayItForwardConnectedThumbnailCarouselProps {
  page: string
  context: string
  padding?: boolean
}

function buildCarousel(connectedCarousel: ConnectedCarousel) {
  return {
    href: connectedCarousel.href,
    title: connectedCarousel.title,
    subtitle: connectedCarousel.subtitle,
    images: connectedCarousel.imagesCollection.items?.map(({ item }) => {
      return `/v${item?.[0]?.version}/${item?.[0]?.public_id}`
    }),
  }
}

export const PayItForwardConnectedThumbnailCarousel: React.FC<PayItForwardConnectedThumbnailCarouselProps> = React.memo(
  ({ page, context, padding = false }) => {
    const { track } = useSafeAnalytics()
    const { pifEnabled, defaultPifConnectedCarousel } = useProjectMeta()
    const { t } = useTranslate('common')

    const payItForward = t('payItForwardv2', 'Pay it Forward')
    const contribute = t('contributeToShowsThatAmplifyLight', 'Contribute to shows that amplify light.')
    const carousel = defaultPifConnectedCarousel && buildCarousel(defaultPifConnectedCarousel)
    const showCarousel = pifEnabled && carousel

    return showCarousel ? (
      <InView
        as="div"
        className="w-full"
        triggerOnce
        onChange={(inView) => {
          if (inView) {
            track('Pif Connected Thumbnail In View', { page })
          }
        }}
      >
        <ConnectedThumbnailCarousel
          heading={carousel.title ?? payItForward}
          subHeading={carousel.subtitle ?? contribute}
          href={carousel.href}
          cloudinaryImagePaths={carousel.images}
          onClick={({ position }) =>
            track('Clicked PIF Connected Thumbnail', {
              position,
              pageContext: context,
            })
          }
          padding={padding}
          priority
        />
      </InView>
    ) : null
  },
)

PayItForwardConnectedThumbnailCarousel.displayName = 'React.memo(PayItForwardConnectedThumbnailCarousel)'
