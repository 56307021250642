import React from 'react'

const ShopifyConfigContext = React.createContext<ShopifyConfigConsumerResponse>({
  storeUrl: undefined,
  handle: undefined,
  accessToken: undefined,
  storeHref: undefined,
  hasShopifyConfig: false,
})

interface ShopifyConfigProviderProps {
  storeUrl?: string
  handle?: string
  accessToken?: string
  storeHref?: string
  children: React.ReactNode
}

export const ShopifyConfigProvider: React.FC<ShopifyConfigProviderProps> = ({
  storeUrl,
  accessToken,
  handle,
  storeHref,
  children,
}) => {
  return (
    <ShopifyConfigContext.Provider
      value={{ storeUrl, accessToken, handle, storeHref, hasShopifyConfig: Boolean(storeUrl && accessToken && handle) }}
    >
      {children}
    </ShopifyConfigContext.Provider>
  )
}

interface ShopifyConfigConsumerResponse {
  storeUrl?: string
  handle?: string
  accessToken?: string
  storeHref?: string
  hasShopifyConfig: boolean
}

export const useShopifyConfig = (): ShopifyConfigConsumerResponse => {
  const state = React.useContext(ShopifyConfigContext)

  if (typeof state === 'undefined') {
    throw new Error('useShopifyConfig must be used within a ShopifyConfigProvider!')
  }

  return state
}
