import React, { FC, useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import classNames from 'classnames'
import { PlayButtonThumbnailOverlay } from '@/atoms/PlayButtonThumbnailOverlay'
import { TitleAndSubtitleDisplay } from '@/atoms/TitleAndSubtitleDisplay'
import { BasicCarousel } from '@/molecules/BasicCarousel'
import { LandscapeTileCard } from '@/molecules/LandscapeRowCollection'
import { ProgressOverlay } from '@/molecules/MediaCard/ProgressOverlay'
import { VideoMetaData } from '@/services/ProjectsService'

interface VideoHorizontalScrollerProps {
  projectSlug: string
  videos: VideoMetaData[]
  padding?: boolean
}

const getVideoQuery = gql`
  query getVideosByPageForProjectWithId($projectSlug: String!, $page: String) {
    project(slug: $projectSlug) {
      id
      videos(page: $page) {
        guid
        title
        subtitle
        posterLandscapeCloudinaryPath
        watchPosition {
          position
        }
        source {
          duration
        }
        page
      }
    }
  }
`

export const VideoHorizontalScroller: FC<VideoHorizontalScrollerProps> = ({ videos, projectSlug, padding = false }) => {
  return (
    <BasicCarousel containerClassName={classNames({ 'px-4 sm:px-8 md:px-12 xl:px-16': padding })}>
      {videos?.map((video) => {
        let progress = 0

        if (video?.watchPosition?.position && video.source?.duration) {
          progress = (video?.watchPosition?.position / video?.source.duration) * 100
        }

        return (
          <div
            key={video.guid}
            className="w-2/3 min-w-[256px] max-w-[300px] shrink-0 snap-center snap-always sm:w-2/5 lg:w-3/5"
          >
            <LandscapeTileCard
              className="mr-4 h-full min-w-full"
              linkModel={{
                linkUrl: `/watch/${projectSlug}/videos/${video.page}/${video.guid}`,
                alt: video.title,
                imageUrl: video.posterLandscapeCloudinaryPath,
                label: video.title,
              }}
            >
              <PlayButtonThumbnailOverlay />
              <ProgressOverlay progress={progress} />
            </LandscapeTileCard>
            <TitleAndSubtitleDisplay className="my-1" subtitle={video.subtitle} />
          </div>
        )
      })}
    </BasicCarousel>
  )
}

export const useProjectVideos = (projectSlug: string, page: string) => {
  const [videos, setVideos] = useState<VideoMetaData[]>([])

  const { data, loading, refetch, error } = useQuery(getVideoQuery, {
    variables: { projectSlug, page },
    errorPolicy: 'all',
  })

  useEffect(() => {
    setVideos(data?.project?.videos ?? [])
  }, [data])

  return {
    videos,
    loading,
    error,
    refetch,
  }
}
