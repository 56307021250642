import React from 'react'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { CrowdfundingSection } from './CrowdfundingSection'
import { FaqSection } from './FaqSection'
import { OverviewSection } from './OverviewSection'

export const WatchAboutView: React.FC = () => {
  return (
    <div className="flex flex-col">
      <PaddedContainer fluid>
        <OverviewSection />
        <CrowdfundingSection />
        <FaqSection />
      </PaddedContainer>
    </div>
  )
}
