import React, { useCallback } from 'react'
import { Else, If, Then } from 'react-if'
import { MediaCard } from '@/molecules/MediaCard'
import { AngelPlayer, createAsset, SmpteController } from '@/organisms/AngelPlayer'
import { incrementUserViewCount } from '@/organisms/GetAppBanner/GetAppBannerUtil'
import { StoryVideo } from '@/services/StoriesService'
import { useSafeTrack } from '@/utils/analytics'

export interface VideoStoryProps {
  id: string
  projectSlug: string
  abbreviated?: boolean
  className?: string
  video?: StoryVideo
  href: string
  isCollectibleStory?: boolean
  onControllerInit: (smpteController: SmpteController) => void
}

const VideoStoryView: React.VFC<VideoStoryProps> = (props) => {
  const track = useSafeTrack()
  const { isCollectibleStory, video, abbreviated, href } = props

  const handleVideoStart = useCallback(() => {
    track('Community Video Content Started', {
      title: video?.title ?? 'Video',
      video_type: 'video',
    })
    incrementUserViewCount()
  }, [track, video?.title])

  return (
    <If condition={abbreviated}>
      <Then>
        <MediaCard
          href={href}
          subtitle={video?.title}
          description={video?.subtitle}
          poster={video?.posterCloudinaryPath || ''}
          className={props.className}
          aspectClassName="aspect-w-6 aspect-h-8 md:aspect-w-1 md:aspect-h-1"
          objectPositionClassName="object-center"
          posterTransformation="c_pad,q_auto,ar_16:9,b_auto"
          includeHoverScale={false}
          projectSlug={props.projectSlug}
        />
      </Then>
      <Else>
        {video && (
          <AngelPlayer
            autoplay
            asset={createAsset({
              projectSlug: isCollectibleStory ? 'the-chosen' : props.projectSlug,
              guid: video.guid,
              name: video.name,
              url: video.source.url,
              poster: video.posterCloudinaryPath,
              title: video.title,
              subtitle: video.subtitle,
              slug: video.slug,
              duration: video.source.duration,
              videoType: 'story',
            })}
            shouldSaveWatchPosition={false}
            onControllerInit={props.onControllerInit}
            overlays={undefined}
            className={props.className}
            aspectClassName="aspect-w-6 aspect-h-8 md:aspect-w-1 md:aspect-h-1 lg:max-h-[60vh]"
            onStart={handleVideoStart}
          >
            <div data-testid="player-episode-mapping" className="mt-6 text-gray-500">
              {video?.title}
            </div>
            <div data-testid="player-episode-title" className="font-semibold text-gray-500">
              {video?.subtitle}
            </div>
          </AngelPlayer>
        )}
      </Else>
    </If>
  )
}

export const VideoStory = React.memo(VideoStoryView)
