import React, { ComponentProps, useMemo } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { LinkButton } from '@/atoms/Button'
import { GildedBorderContainer } from '@/atoms/GildedBorderContainer'
import { ArrowRightIcon } from '@/atoms/Icons/ArrowRightIcon'
import { GuildShield } from '@/atoms/Icons/GuildShield'
import { Image } from '@/atoms/Image'
import { LabelSM, ParagraphSM, TitleLG } from '@/atoms/Text'
import { slugs } from '@/constants'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { buildAnalyticsLink } from '@/utils/AnalyticsLinkBuilder'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useTranslate } from '@/utils/translate/translate-client'
import useWindowSize from '@/utils/useWindowSize'

const PROJECT_INFO = {
  [slugs.tuttleTwins]: {
    title: 'Tuttle Twins',
    backgroundImagePath: 'v1726675778/angel-studios/guild/guild-join-tuttle/blur',
    primaryImage: {
      cloudinaryPath: 'v1726611070/angel-studios/guild/guild-join-tuttle/derek',
      alt: 'Derek',
    },
    secondaryImage: {
      cloudinaryPath: 'v1726611188/angel-studios/guild/guild-join-tuttle/grandma_gabby',
      alt: 'Grandma Gabby',
    },
  },
  [slugs.wingfeatherSaga]: {
    title: 'The Wingfeather Saga Season 3',
    backgroundImagePath: 'v1713498366/angel-studios/guild/guild-join-wingfeather/banner_background',
    primaryImage: {
      cloudinaryPath: 'v1713498371/angel-studios/guild/guild-join-wingfeather/Kalmar_Promo_Pose_005_v01',
      alt: 'Tink ready for adventure',
    },
    secondaryImage: {
      cloudinaryPath: 'v1713498377/angel-studios/guild/guild-join-wingfeather/NuggetPose_02',
      alt: 'Nugget',
    },
  },
  [slugs.youngDavid]: {
    title: 'Young David',
    backgroundImagePath: 'v1726675754/angel-studios/guild/guild-join-young-david/blur',
    primaryImage: {
      cloudinaryPath: 'v1726678314/angel-studios/guild/guild-join-young-david/YoungDavid__3D__Pose2',
      alt: 'Young David',
    },
    secondaryImage: {
      cloudinaryPath: 'v1726678292/angel-studios/guild/guild-join-young-david/Tali_YoungDavid__3D__Pose4_1',
      alt: 'Tali',
    },
  },
}

interface Props {
  className?: string
  onClick: () => void
  linkContext: string
  slug: string
}

export const GuildJoinTheAdventureBanner: React.FC<Props> = ({ className, onClick, linkContext, slug }) => {
  const { asPath } = useRouter()
  const { t } = useTranslate('watch')
  const { logoCloudinaryPath, name } = useProjectMeta()
  const { innerWidth } = useWindowSize()

  const Wrap = useMemo(
    () =>
      innerWidth < 640 // sm screen size breakpoint
        ? (props: ComponentProps<typeof GildedBorderContainer>) => <div {...props} />
        : GildedBorderContainer,
    [innerWidth],
  )

  const projectInfo = PROJECT_INFO[slug]

  if (!projectInfo) return null

  return (
    <div className={classNames('-mx-4 pt-[20px] sm:mx-0 sm:mb-0 sm:rounded-3xl md:w-full', className)}>
      <Wrap className="sm:rounded-3xl">
        <div className="relative">
          <Image src={projectInfo.backgroundImagePath} fill className="object-cover sm:rounded-2xl" alt="" />
          <div className="relative mx-auto max-w-screen-lg p-4 pl-[182px] sm:px-8 sm:pl-[256px] lg:pl-[145px] xl:pr-[145px]">
            <div className="absolute inset-y-0 -left-[50px] -top-6 z-50 overflow-hidden sm:!left-0 lg:!left-2">
              <Image
                src={projectInfo.primaryImage.cloudinaryPath}
                width={innerWidth < 1024 ? 232 : 121}
                height={innerWidth < 1024 ? 266 : 140}
                alt={projectInfo.primaryImage.alt}
              />
            </div>

            <div className="relative flex flex-col items-start gap-3 lg:flex-row lg:items-center">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-1 lg:justify-center">
                  <GuildShield />
                  {logoCloudinaryPath && (
                    <img className="h-9" src={getCloudinaryImageUrl({ path: logoCloudinaryPath })} alt={name} />
                  )}
                </div>

                <TitleLG className="lg:whitespace-nowrap">{t('joinTheAdventure', 'Join the Adventure!')}</TitleLG>
              </div>

              <ParagraphSM className="text-balance lg:text-center">
                {t(
                  'joinGuildToHelpProject',
                  'By joining the Angel Guild, you are directly helping fund {{projectTitle}}.',
                  { projectTitle: projectInfo.title },
                )}
              </ParagraphSM>

              <LinkButton
                className="rounded-xl border-0 bg-white/20 px-6 py-2"
                href={buildAnalyticsLink({
                  href: `/guild/join/${slug}`,
                  path: asPath,
                  campaign: slug,
                  linkContext,
                })}
                onClick={onClick}
                variant="plain"
                target="_self"
                prefetch
              >
                <div className="relative flex w-full flex-row items-center justify-center text-white">
                  <LabelSM className="mr-1 whitespace-nowrap">{t('joinTheGuild', 'Join The Guild')}</LabelSM>
                  <ArrowRightIcon size={24} color="current" />
                </div>
              </LinkButton>
            </div>
            <div className="absolute -top-6 bottom-0 right-4 z-50 hidden overflow-hidden xl:block">
              <Image
                src={projectInfo.secondaryImage.cloudinaryPath}
                width={104}
                height={140}
                alt={projectInfo.secondaryImage.alt}
              />
            </div>
          </div>
        </div>
      </Wrap>
    </div>
  )
}
