import React from 'react'
import classNames from 'classnames'
import { Case, Else, If, Switch, Then } from 'react-if'
import { AsDiv, CaptionXS, Footer, ParagraphSM } from '@/atoms/Text'
import { useGuildEarlyAccessModalContext } from '@/organisms/GuildEarlyAccessModal/GuildEarlyAccessModal'
import { formatPrice } from '@/services/PriceFormatter'
import { ReactFCC } from '@/types/react'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'
import { Translate } from '@/utils/translate/translate-client'

export const GuildPlanTypeOptions: ReactFCC = () => {
  const context = useGuildEarlyAccessModalContext()
  const { t } = useTranslate('watch')
  const { locale } = useLocale()

  if (!context) return null

  const { selectedPlan, setSelectedPlan, yearlyPlan, monthlyPremiumPlan, monthlyBasicPlan, promotion } = context
  const monthlyBasicCost = formatPrice(monthlyBasicPlan.price, {
    locale,
    includeDecimals: false,
    currency: monthlyBasicPlan.currency,
  })
  const monthlyPremiumCost = formatPrice(monthlyPremiumPlan.price, {
    locale,
    includeDecimals: false,
    currency: monthlyPremiumPlan.currency,
  })
  const yearlyCost = formatPrice(yearlyPlan.price, { locale, includeDecimals: false, currency: yearlyPlan.currency })
  const promoCost = promotion?.promoPriceDiscount
    ? formatPrice(promotion?.offer?.price - promotion.promoPriceDiscount, {
        locale,
        includeDecimals: false,
        currency: promotion?.offer.currency,
      })
    : null

  return (
    <div className="flex flex-row justify-center gap-2 pt-4">
      <SubscriptionOption
        isSelected={selectedPlan === monthlyBasicPlan}
        onClick={() => {
          setSelectedPlan(monthlyBasicPlan)
        }}
        planType={monthlyBasicPlan.planType}
      >
        <ParagraphSM weight="semibold">
          <Translate t={t} i18nKey="guildMonthlyBasicCost" values={{ monthlyCost: monthlyBasicCost }}>
            {{ monthlyCost: monthlyBasicCost }}/month
          </Translate>
        </ParagraphSM>
      </SubscriptionOption>
      <SubscriptionOption
        isSelected={selectedPlan === monthlyPremiumPlan}
        onClick={() => {
          setSelectedPlan(monthlyPremiumPlan)
        }}
        planType={monthlyPremiumPlan.planType}
      >
        <ParagraphSM weight="semibold">
          <Translate t={t} i18nKey="guildMonthlyPremiumCost" values={{ monthlyCost: monthlyPremiumCost }}>
            {{ monthlyCost: monthlyPremiumCost }}/month
          </Translate>
        </ParagraphSM>
      </SubscriptionOption>

      <SubscriptionOption
        isSelected={selectedPlan === yearlyPlan}
        onClick={() => {
          setSelectedPlan(yearlyPlan)
        }}
        planType={yearlyPlan.planType}
      >
        <If condition={Boolean(promotion)}>
          <Then>
            <ParagraphSM as={AsDiv} weight="semibold">
              <Translate t={t} i18nKey="guildYearlyCost" values={{ yearlyCost: promoCost }}>
                {{ yearlyCost }}/year
              </Translate>
            </ParagraphSM>
            <Footer className="-mt-1" as={AsDiv} color="core-copper" weight="bold">
              {promotion?.abbreviatedPitch}
            </Footer>
          </Then>
          <Else>
            <ParagraphSM as={AsDiv} weight="semibold">
              <Translate t={t} i18nKey="guildYearlyCost" values={{ yearlyCost }}>
                {{ yearlyCost }}/year
              </Translate>
            </ParagraphSM>
            <Footer className="-mt-1" as={AsDiv} color="core-copper" weight="bold">
              {t('threeMonthsSavings', '3 Months Savings')}
            </Footer>
          </Else>
        </If>
      </SubscriptionOption>
    </div>
  )
}

interface SubscriptionOptionProps {
  isSelected: boolean
  onClick: () => void
  planType: 'premium' | 'basic'
}

const SubscriptionOption: ReactFCC<SubscriptionOptionProps> = ({ children, isSelected, onClick, planType }) => {
  const { t } = useTranslate('common')
  return (
    <div
      className={classNames(
        'rounded-full h-[52px] w-[155px] flex flex-col justify-center items-center cursor-pointer',
        isSelected ? 'border-2 border-teal-light bg-teal-light/20' : 'border-[1px] border-core-gray-200',
      )}
      onClick={onClick}
    >
      <CaptionXS className="uppercase" weight="semibold" color="core-gray-600">
        <Switch>
          <Case condition={planType === 'premium'}>
            <Translate i18nKey="premium" t={t}>
              Premium
            </Translate>
          </Case>
          <Case condition={planType === 'basic'}>
            <Translate i18nKey="basic" t={t}>
              Basic
            </Translate>
          </Case>
        </Switch>
      </CaptionXS>
      {children}
    </div>
  )
}
