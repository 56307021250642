import React from 'react'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { Image } from '@/atoms/Image'
import { TitleLG, TitleMD } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'

type Props = {
  onClose: () => void
}

export const GuildEarlyAccessModalHeader: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslate('watch')

  return (
    <div>
      <div className="flex cursor-pointer flex-row justify-end">
        <div className="rounded-full bg-core-gray-100 p-1.5" onClick={onClose}>
          <CloseIcon color="core-gray-700" size={18} />
        </div>
      </div>

      <div className="hidden flex-row justify-center 2xl:flex">
        <Image
          width={86}
          height={127}
          src="v1700061429/angel-studios/guild/ckglvwwvjj5nqxskm8zf.webp"
          alt="Angel Guild"
          className="h-[100px] w-auto lg:h-[127px]"
        />
      </div>

      <TitleLG className="mt-4 hidden text-center 2xl:block" weight="bold">
        {t('guildOnlyEarlyAccess', 'Guild-Only Early Access')}
      </TitleLG>

      <TitleMD className="mt-0 flex justify-center gap-2 text-center 2xl:hidden" weight="bold">
        <Image
          width={86}
          height={127}
          src="v1700061429/angel-studios/guild/ckglvwwvjj5nqxskm8zf.webp"
          alt="Angel Guild"
          className="h-[35px] w-auto"
        />{' '}
        {t('guildOnlyEarlyAccess', 'Guild-Only Early Access')}
      </TitleMD>
    </div>
  )
}
