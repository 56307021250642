import dynamic, { DynamicOptions } from 'next/dynamic'
import { reportErrorToBugsnag } from '@/utils/bugsnag'
import { StoryCardProps } from '@/views/WatchProjectView/CommunityView/StoryCard/StoryCard'

export { shareTitle } from './StoryCard'

export const StoryCard = dynamic(
  import('./StoryCard')
    .then((mod) => mod.StoryCard)
    .catch(() => reportErrorToBugsnag(`Failed to load the StoryCard!`)) as DynamicOptions<StoryCardProps>,
  { ssr: false },
)
