import { createContext } from 'react'
import { Project } from '@/services/ProjectsService'
import { ProjectTheme } from '@/utils/project-themes/types'

export interface WatchProjectContextType {
  project: Project
  projectTheme: ProjectTheme
  departmentPath?: string[]
}

export const WatchProjectContext = createContext<WatchProjectContextType>({
  projectTheme: {} as ProjectTheme,
  project: {} as Project,
})
