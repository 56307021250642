import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { ChevronLeftIcon } from '@/atoms/Icons/ChevronLeftIcon'
import { ChevronRightIcon } from '@/atoms/Icons/ChevronRightIcon'
import { getHexColor } from '@/atoms/utils'
import type { NamedColor } from '@/constants/types'

interface HorizontalCollectionProps {
  children: React.ReactNode
  backgroundColor?: NamedColor
  arrowColor?: NamedColor
  disabledArrowColor?: NamedColor
  stepScale?: number
  arrowClassName?: string
  sectionClassName?: string
}

export const HorizontalCollection: React.FC<HorizontalCollectionProps> = ({
  children,
  backgroundColor = 'gray-950',
  arrowColor = 'white',
  disabledArrowColor = 'gray-600',
  stepScale,
  arrowClassName,
  sectionClassName,
}) => {
  const horizontalCollectionContainer = useRef<HTMLDivElement>(null)
  const arrowsRef = useRef<HTMLDivElement>(null)
  const [isShowArrows, setShowArrows] = useState<boolean>(false)
  const [scrollPosition, setScrollPosition] = useState<number>(0)

  const updateOverflow = () => {
    if (!horizontalCollectionContainer?.current) {
      return
    }

    setShowArrows(horizontalCollectionContainer.current.scrollWidth > horizontalCollectionContainer.current.offsetWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', updateOverflow)

    updateOverflow()
    return () => {
      window.removeEventListener('resize', updateOverflow)
    }
  }, [])

  useEffect(() => {
    updateOverflow()
  }, [children])

  let distanceToScroll = 0

  if (horizontalCollectionContainer.current) {
    distanceToScroll = stepScale
      ? horizontalCollectionContainer.current.offsetWidth * stepScale
      : horizontalCollectionContainer?.current?.offsetWidth
  }

  const rightArrowEnabled =
    scrollPosition + (horizontalCollectionContainer?.current?.offsetWidth ?? 0) <
    (horizontalCollectionContainer?.current?.scrollWidth ?? 0)
  const leftArrowEnabled = scrollPosition > 0

  const scrollRight = () => {
    if (!horizontalCollectionContainer?.current) return
    if (!rightArrowEnabled) return

    const updatedScrollLeft = Math.min(
      horizontalCollectionContainer.current.scrollLeft + distanceToScroll,
      horizontalCollectionContainer.current.scrollWidth - horizontalCollectionContainer.current.offsetWidth,
    )

    horizontalCollectionContainer.current.scrollTo({
      top: 0,
      left: updatedScrollLeft,
      behavior: 'smooth',
    })
  }

  const scrollLeft = () => {
    if (!horizontalCollectionContainer?.current) return
    if (!leftArrowEnabled) return

    const updatedScrollLeft = Math.max(horizontalCollectionContainer.current.scrollLeft - distanceToScroll, 0)

    horizontalCollectionContainer.current.scrollTo({
      top: 0,
      left: updatedScrollLeft,
      behavior: 'smooth',
    })
  }

  const onScroll: React.UIEventHandler<HTMLElement> = () => {
    if (horizontalCollectionContainer?.current) {
      setScrollPosition(horizontalCollectionContainer.current.scrollLeft)
    }
  }

  const wrapperClasses = classNames(
    'flex flex-nowrap w-full overflow-hidden relative',
    'before:content-[""] before:block before:w-6 before:h-full before:absolute before:top-0 before:opacity-0 before:transition-opacity before:pointer-events-none before:z-10',
    'after:content-[""] after:block after:w-6 after:h-full after:absolute after:top-0 after:opacity-0 after:transition-opacity after:pointer-events-none after:z-10',
    isShowArrows && 'before:bg-faded-left-edge before:-left-[2px] after:bg-faded-right-edge after:right-0',
    leftArrowEnabled && 'before:opacity-100',
    rightArrowEnabled && 'after:opacity-100',
  )

  return (
    <section className={classNames('relative flex flex-nowrap w-full', sectionClassName)}>
      <div className={wrapperClasses}>
        <nav ref={horizontalCollectionContainer} className="horizontal-scroller w-full" onScroll={onScroll}>
          {children}
        </nav>
      </div>
      {isShowArrows && (
        <div ref={arrowsRef} className="flex" style={{ backgroundColor: getHexColor(backgroundColor) }}>
          <div
            onClick={scrollLeft}
            className={classNames('h-full px-3 pt-1', { 'cursor-pointer': leftArrowEnabled }, arrowClassName)}
          >
            <ChevronLeftIcon color={leftArrowEnabled ? arrowColor : disabledArrowColor} />
          </div>
          <div
            onClick={scrollRight}
            className={classNames('px-3 pt-1 h-full', { 'cursor-pointer': rightArrowEnabled }, arrowClassName)}
          >
            <ChevronRightIcon color={rightArrowEnabled ? arrowColor : disabledArrowColor} />
          </div>
        </div>
      )}
    </section>
  )
}
