import React from 'react'
import { JsonLd, JsonLdProps } from './JsonLd'
import { PodcastEpisode } from './types'

interface PodcastEpisodeJsonLdProps extends JsonLdProps, PodcastEpisode {}

export const PodcastEpisodeJsonLd: React.FC<PodcastEpisodeJsonLdProps> = ({
  type = 'PodcastEpisode',
  keyOverride,
  ...rest
}: PodcastEpisodeJsonLdProps) => <JsonLd type={type} keyOverride={keyOverride} {...rest} scriptKey="PodcastEpisode" />
