import React from 'react'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { BackgroundPosition } from '../WatchProjectHero'

interface ResponsiveBackgroundContainerProps {
  cloudinaryImgPath: string
  theaterBackgroundPosition?: BackgroundPosition
}

export const ResponsiveBackgroundContainer = React.forwardRef<HTMLDivElement, ResponsiveBackgroundContainerProps>(
  (props, ref) => {
    const { cloudinaryImgPath, theaterBackgroundPosition } = props

    return (
      <div className="fixed left-0 top-0 min-h-[70vh] w-screen transition-opacity" ref={ref}>
        <ResponsiveBackgroundImage
          preBackgroundStyles="linear-gradient(0deg, #151515 0%, rgba(21, 21, 21, 0) 50%), linear-gradient(225deg, rgba(21, 21, 21, 0.2) 50%, #151515 99.89%),"
          src={cloudinaryImgPath}
          className={theaterBackgroundPosition ?? 'bg-top'}
        />
        <div className="absolute inset-x-0 top-0 h-12 w-full bg-gradient-to-b from-gray-950 to-transparent sm:h-24 lg:h-32" />
      </div>
    )
  },
)

ResponsiveBackgroundContainer.displayName = 'ResponsiveBackgroundContainer'
