import React, { useCallback } from 'react'
import { useRouter } from 'next/router'
import { LinkButton } from '@/atoms/Button'
import { CaptionSM, EyebrowSM, Footer, LabelXS } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { ShopifyProduct, useShopifyCollection } from '@/services/Shopify/ShopifyService'
import { buildAnalyticsLink } from '@/utils/AnalyticsLinkBuilder'
import { useSafeAnalytics } from '@/utils/analytics'
import { cLogger } from '@/utils/logging/client-logger'
import { useTranslate } from '@/utils/translate/translate-client'

export interface MerchDiscount {
  code: string
  value: number
}

export interface WatchMerchCardProps {
  accessToken: string
  discount?: MerchDiscount
  analytics: {
    content_id?: string
    context: string
    program: string
  }
  handle: string
  storeUrl: string
  storeHref?: string
  utm?: {
    campaign: string
    linkContext: string
  }
  slug: string
  isGuildMember?: boolean
}

const buildMerchDiscountUrl = (product: ShopifyProduct, discount: MerchDiscount) => {
  try {
    const parsedUrl = new URL(product?.onlineStoreUrl)
    return `${parsedUrl.origin}/discount/${discount.code}?redirect=${parsedUrl.pathname}`
  } catch (error) {
    cLogger().error('An error occurred creating a merch discount url', error)
    return product?.onlineStoreUrl
  }
}

export const WatchMerchCard: React.FC<WatchMerchCardProps> = ({
  accessToken,
  analytics,
  handle,
  storeUrl,
  storeHref,
  utm,
  discount,
  slug,
  isGuildMember = false,
}) => {
  const { asPath } = useRouter()
  const { data, error } = useShopifyCollection({ storeUrl, accessToken, handle })
  const { t } = useTranslate('ads')
  const { track } = useSafeAnalytics()
  const utmLinkContext = utm ? utm.linkContext : 'generic'
  const utmCampaign = utm ? utm.campaign : 'angel-web-project'

  const buildProductUrl = useCallback(
    (product: ShopifyProduct) => {
      const onlineStoreUrl = product?.onlineStoreUrl

      if (discount) return buildMerchDiscountUrl(product, discount)

      return onlineStoreUrl
    },
    [discount],
  )

  const massaged =
    data?.collection?.products?.edges
      .map(({ node }) => {
        return {
          id: node.id,
          minPrice: {
            value: node.priceRange.minVariantPrice.amount,
            currency: node.priceRange.minVariantPrice.currencyCode,
          },
          compareAtMinPrice: {
            value: node.compareAtPriceRange.minVariantPrice.amount,
            currency: node.compareAtPriceRange.minVariantPrice.currencyCode,
          },
          title: node.title,
          description: node.description,
          url: buildProductUrl(node),
          imageUrl: node.featuredImage.url,
        }
      })
      .filter((item) => item?.url && item?.imageUrl && item?.title) || []

  if (error) {
    return null
  }

  const defaultCard = massaged[0] ?? null

  return (
    defaultCard && (
      <div
        id="merch-card"
        style={{ backgroundColor: 'rgba(255, 255, 255, 0.10)' }}
        className="flex max-w-full flex-row items-start gap-2 rounded-2xl p-4 backdrop-blur-2xl lg:max-w-[434px] lg:gap-4"
      >
        <img
          alt={defaultCard.title}
          src={defaultCard.imageUrl}
          className="max-h-[88px] w-auto rounded-lg"
          loading="lazy"
        />
        <div className="relative flex h-full w-full max-w-full flex-col gap-2 overflow-hidden">
          <EyebrowSM weight="semibold" color="core-gray-400" className="flex max-w-full flex-wrap uppercase">
            {t('shopAngelMerch', 'Shop Angel Merch')}
          </EyebrowSM>
          <CaptionSM className="max-h-4 max-w-full flex-1 overflow-hidden text-ellipsis whitespace-nowrap lg:max-w-[239px]">
            {defaultCard.title}
          </CaptionSM>
          <Footer className="line-clamp-4 max-h-[56px] max-w-full flex-1 overflow-hidden text-ellipsis lg:max-w-[239px]">
            {defaultCard.description}
          </Footer>
          <div className="mt-2 flex flex-row gap-2 lg:gap-4">
            <LinkButton
              variant="gray-400"
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.10)' }}
              className="border-0 px-[14px] py-2 text-white backdrop-blur-3xl"
              href={buildAnalyticsLink({
                href: storeHref ?? defaultCard.url,
                path: asPath,
                campaign: utmCampaign,
                linkContext: utmLinkContext,
              })}
              onClick={() => {
                track('Clicked Merch Item', {
                  ...analytics,
                  itemName: defaultCard.title,
                  itemUrl: defaultCard.url,
                })
              }}
            >
              <LabelXS weight="semibold" className="lg:text-xs">
                {isGuildMember ? t('shopNowTwentyPercent', 'Shop Now For 20% Off') : t('shopNow', 'Shop Now')}
              </LabelXS>
            </LinkButton>
            {!isGuildMember && (
              <LinkButton
                variant="gray-400"
                className="backdrop-blur-3x border-0 !bg-core-gray-900 px-[14px] py-2 text-white"
                href={`${paths.guild.join}/${slug}`}
                onClick={() => {
                  track('Clicked Guild Merch Join For Discount', {
                    ...analytics,
                    itemName: defaultCard.title,
                    itemUrl: defaultCard.url,
                  })
                }}
              >
                <LabelXS weight="semibold" className="lg:text-xs">
                  {t('joinTwentyPercent', 'Join For 20% Off')}
                </LabelXS>
              </LinkButton>
            )}
          </div>
        </div>
      </div>
    )
  )
}
