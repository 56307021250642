import React from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { LinkButton } from '@/atoms/Button'
import { GuildIcon } from '@/atoms/Icons/GuildIcon'
import { buildAnalyticsLink } from '@/utils/AnalyticsLinkBuilder'

interface JoinGuildButtonProps {
  projectSlug: string
  className?: string
  text?: string
  onClick?: () => void
  target?: '_blank' | '_self'
  href?: string
}

export const JoinGuildButton: React.FC<JoinGuildButtonProps> = ({
  className,
  text,
  onClick,
  target,
  projectSlug,
  href = '/guild/join',
}) => {
  const { asPath } = useRouter()

  return (
    <LinkButton
      className={classNames('py-3 px-10 md:w-fit fill-white hover:fill-black backdrop-blur-md', className)}
      href={buildAnalyticsLink({ href, path: asPath, campaign: projectSlug, linkContext: 'watch-tab' })}
      onClick={onClick}
      variant="white"
      target={target}
      prefetch
      outline
    >
      <div className="relative flex w-full flex-row items-center justify-center">
        <GuildIcon size={24} color1="current" className="fill-inherit" />
        <div className="ml-2">{text}</div>
      </div>
    </LinkButton>
  )
}
