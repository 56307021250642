import React, { FC } from 'react'
import classNames from 'classnames'
import { LinkButton } from '@/atoms/Button'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { AsSpan, CaptionMD, HeadingXS, ParagraphSM } from '@/atoms/Text'
import { AppBannerAnalyticsContext } from '@/organisms/GetAppBanner/types'
import { useSafeAnalytics } from '@/utils/analytics'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface GetAppBannerProps {
  deepLink: string
  showBanner: boolean
  onClose: () => void
  analytics: {
    projectSlug?: string
    context: AppBannerAnalyticsContext
  }
}

export const GetAppBanner: FC<GetAppBannerProps> = ({ deepLink, showBanner, analytics, onClose }) => {
  const { t } = useTranslate('common')
  const { track } = useSafeAnalytics()

  if (!deepLink) return null

  return (
    <div
      id="get-app-banner"
      className={classNames([
        'fixed z-[1000] w-full h-fit bottom-0 bg-white rounded-t-2xl transition-all translate-y-full duration-500',
        showBanner ? '!translate-y-0' : '',
      ])}
    >
      <div className="p-4 text-center">
        <div
          onClick={() => {
            onClose()
            track('Get App Banner Dismissed', analytics)
          }}
        >
          <CloseIcon size={24} className="ml-auto" />
        </div>
        <div className="mb-6">
          <Image
            alt="Angel App"
            src="/h_64,w_64,q_auto/v1676478339/angel-studios/App_Icon.webp"
            height={64}
            width={64}
          />
        </div>
        <HeadingXS color="gray-950" weight="bold" className="mb-2">
          <Translate t={t} i18nKey="promptOpenInTheAngelApp">
            Open In <wbr />
            The Angel App
          </Translate>
        </HeadingXS>
        <ParagraphSM className="mb-4">
          <Translate t={t} i18nKey="streamAngelOriginalsOnTheAngelAppV2">
            Stream your favorite Angel Originals on the Angel App.
          </Translate>
        </ParagraphSM>

        <LinkButton
          external
          rel="nofollow"
          href={deepLink as string}
          variant="oxide-bright"
          className="mx-auto mb-6 w-fit justify-center px-6 py-3"
          onClick={() => {
            track('Get App Clicked', { location: 'GetAppBanner', ...analytics })
            track('Get App Banner Button Clicked', analytics)
          }}
        >
          <CaptionMD as={AsSpan} weight="bold">
            <Translate t={t} i18nKey="openInTheFreeApp">
              Open In The App
            </Translate>
          </CaptionMD>
        </LinkButton>
      </div>
    </div>
  )
}
