import { FC } from 'react'
import { AsH3, TitleLG } from '@/atoms/Text'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { PayItForwardButton } from '@/molecules/PayItForwardButton'
import { ProjectFundingGoalDisplay } from '@/organisms/ProjectFundingGoalDisplay'
import { useGoal } from '@/services/GoalService'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { AboutSectionContainer } from '@/views/WatchAboutView/AboutSectionContainer'

export const CrowdfundingSection: FC = () => {
  const { t } = useTranslate('watch')
  const project = useProjectMeta()
  const goal = useGoal({ slug: project.crowdfundingSlug })

  return project.crowdfundingSlug ? (
    <AboutSectionContainer id="crowdfunding">
      <TitleLG weight="bold" as={AsH3} className="mb-12">
        <Translate t={t} i18nKey="crowdFundingProgress">
          Crowdfunding Progress
        </Translate>
      </TitleLG>
      <div className="mb-12">
        <ProjectFundingGoalDisplay fullWidth {...goal} />
      </div>
      <PayItForwardButton projectSlug={project.slug} />
    </AboutSectionContainer>
  ) : null
}
