import React from 'react'
import { BaseIconProps, scaleDimensions } from '../utils'

export const GuildShield: React.FC<BaseIconProps> = ({ className, size = 22 }) => {
  const { width, height } = scaleDimensions(size, 22, 24)

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 24"
      fill="none"
    >
      <path
        d="M1.737.666h18.526c.602 0 1.09.512 1.09 1.147v9.075c0 4.844-3.487 10.263-9.36 12.447a2.85 2.85 0 0 1-1.985 0C4.135 21.151.648 15.732.648 10.888V1.814c0-.635.487-1.148 1.09-1.148Z"
        fill="url(#a)"
      />
      <path
        d="M1.737.666h18.526c.602 0 1.09.512 1.09 1.147v9.075c0 4.844-3.487 10.263-9.36 12.447a2.85 2.85 0 0 1-1.985 0C4.135 21.151.648 15.732.648 10.888V1.814c0-.635.487-1.148 1.09-1.148Z"
        fill="url(#b)"
        fillOpacity=".51"
      />
      <path
        d="M1.737.666h18.526c.602 0 1.09.512 1.09 1.147v9.075c0 4.844-3.487 10.263-9.36 12.447a2.85 2.85 0 0 1-1.985 0C4.135 21.151.648 15.732.648 10.888V1.814c0-.635.487-1.148 1.09-1.148Z"
        fill="url(#c)"
        fillOpacity=".1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.737.166h18.526c.873 0 1.575.732 1.575 1.63v8.944c0 5.285-4.05 11.13-10.706 13.074a.47.47 0 0 1-.264 0C4.212 21.87.162 16.024.162 10.74V1.797C.162.898.864.166 1.737.166ZM11 23.333c6.47-1.89 10.353-7.556 10.353-12.593V1.797c0-.626-.488-1.131-1.09-1.131H1.737c-.602 0-1.09.505-1.09 1.131v8.943C.647 15.777 4.53 21.444 11 23.333Z"
        fill="url(#d)"
      />
      <path
        d="M1.444 11.912c.159 5.377 5.09 9.019 8.909 10.753-1.056-8.248-1.06-13.002 0-21.333h-8.91c-.313 0-.044 9.089-.001 10.523l.002.057Z"
        fill="url(#e)"
        fillOpacity=".45"
      />
      <path
        d="M11.059 9.8h3.557c.068.006.136.039.167.093a.29.29 0 0 1 .079.198v4.822c0 .039-.01.082-.026.121a.267.267 0 0 1-.074.099 7.106 7.106 0 0 1-3.53.867c-2.825-.072-5.085-2.37-5.085-5.333 0-2.957 2.26-5.371 5.085-5.333 1.192.039 2.51.587 3.478 1.51a.232.232 0 0 1 .073.087c.021.033.032.071.032.11a.299.299 0 0 1-.021.115.362.362 0 0 1-.063.093l-1.104 1.213a.352.352 0 0 1-.193.06.352.352 0 0 1-.194-.06c-.539-.555-1.171-.758-1.857-.774-1.72-.038-2.788 1.344-2.788 2.962 0 1.635 1.067 2.958 2.804 2.996a3.42 3.42 0 0 0 1.26-.203V11.94c-.313-.017-1.328-.017-1.61-.017a.346.346 0 0 1-.105-.016.322.322 0 0 1-.089-.06.36.36 0 0 1-.063-.094.256.256 0 0 1-.02-.11v-1.569c0-.038.005-.076.02-.11a.36.36 0 0 1 .063-.093.218.218 0 0 1 .094-.06c.037-.01.073-.016.11-.01Z"
        fill="url(#f)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.792 11.753a.257.257 0 0 1-.02-.11v-1.57c0-.038.005-.076.02-.109a.363.363 0 0 1 .063-.093.218.218 0 0 1 .094-.06.25.25 0 0 1 .11-.011h3.557c.068.005.136.038.167.093a.29.29 0 0 1 .079.197v4.823c0 .038-.01.082-.027.12a.267.267 0 0 1-.073.1 7.108 7.108 0 0 1-3.53.866c-2.825-.072-5.085-2.37-5.085-5.333 0-2.957 2.26-5.37 5.085-5.332 1.192.038 2.51.587 3.478 1.508a.233.233 0 0 1 .073.088c.021.033.032.072.032.11a.3.3 0 0 1-.021.115.363.363 0 0 1-.063.093l-1.104 1.213a.352.352 0 0 1-.193.06.352.352 0 0 1-.194-.06c-.539-.554-1.172-.757-1.857-.774-1.72-.038-2.788 1.344-2.788 2.963 0 1.635 1.067 2.957 2.804 2.995.429.011.853-.055 1.26-.203V11.94c-.313-.016-1.328-.016-1.61-.016a.347.347 0 0 1-.105-.017c-.032-.016-.063-.033-.09-.06a.362.362 0 0 1-.062-.093Zm1.544.51a90.03 90.03 0 0 0-1.287-.007.663.663 0 0 1-.195-.03l-.03-.009-.027-.014-.005-.002a.622.622 0 0 1-.29-.302.594.594 0 0 1-.054-.256v-1.57c0-.064.008-.16.054-.256a.692.692 0 0 1 .122-.18m1.712 2.626v.934a3.122 3.122 0 0 1-.93.115c-.786-.017-1.402-.323-1.822-.792-.423-.472-.665-1.127-.665-1.87 0-1.468.95-2.663 2.457-2.63.622.016 1.168.196 1.636.677l.024.025.028.019a.669.669 0 0 0 .37.114.668.668 0 0 0 .37-.114l.032-.022 1.129-1.24a.694.694 0 0 0 .119-.177l.004-.009.004-.008a.639.639 0 0 0 .046-.245.547.547 0 0 0-.073-.275.566.566 0 0 0-.15-.18C13.896 5.618 12.51 5.04 11.243 5h-.006c-3.016-.042-5.412 2.534-5.412 5.665 0 3.149 2.404 5.59 5.4 5.666h.002a7.423 7.423 0 0 0 3.688-.905l.03-.017.028-.024a.599.599 0 0 0 .162-.223.674.674 0 0 0 .051-.25V10.09a.63.63 0 0 0-.146-.403.54.54 0 0 0-.398-.22h-3.564a.574.574 0 0 0-.218.023.539.539 0 0 0-.235.147"
        fill="url(#g)"
      />
      <defs>
        <linearGradient id="a" x1="3.883" y1="2.188" x2="12.429" y2="21.875" gradientUnits="userSpaceOnUse">
          <stop offset=".044" stopColor="#C57B46" />
          <stop offset=".334" stopColor="#DBA775" />
          <stop offset=".721" stopColor="#CC7A3D" />
          <stop offset=".929" stopColor="#A25A38" />
        </linearGradient>
        <linearGradient id="b" x1=".809" y1="-.518" x2="3.777" y2="6.664" gradientUnits="userSpaceOnUse">
          <stop offset=".018" stopColor="#FFFCF6" stopOpacity=".18" />
          <stop offset=".523" stopColor="#FFFAEF" />
          <stop offset=".867" stopColor="#F8F8F8" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="c" x1="12.941" y1="22.313" x2="8.072" y2="12.332" gradientUnits="userSpaceOnUse">
          <stop offset=".018" stopColor="#FFFCF6" stopOpacity=".18" />
          <stop offset=".523" stopColor="#FFFAEF" />
          <stop offset=".867" stopColor="#F8F8F8" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="d" x1="10.522" y1="-.491" x2="13.569" y2="21.014" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" />
          <stop offset=".127" stopColor="#DDAA85" />
          <stop offset="1" stopColor="#5F3116" />
        </linearGradient>
        <linearGradient id="e" x1="3.353" y1="2.373" x2="10.763" y2="21.745" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" stopOpacity=".42" />
          <stop offset="1" stopColor="#fff" stopOpacity=".08" />
        </linearGradient>
        <linearGradient id="f" x1="7.523" y1="5.511" x2="13.285" y2="15.822" gradientUnits="userSpaceOnUse">
          <stop offset=".042" stopColor="#141313" />
          <stop offset=".594" stopColor="#4A2B27" />
        </linearGradient>
        <linearGradient id="g" x1="11.385" y1="17.042" x2="7.785" y2="4.327" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" />
          <stop offset=".127" stopColor="#DDAA85" stopOpacity=".4" />
          <stop offset="1" stopColor="#5F3116" />
        </linearGradient>
      </defs>
    </svg>
  )
}
