import { FC } from 'react'
import { useExperiment } from '@optimizely/react-sdk'
import { useRouter } from 'next/router'
import { FeatureAnnouncement } from '@/services/FeatureAnnouncementService'
import { shouldShowAnnouncementToUser } from '@/services/FeatureAnnouncementService/FeatureAnnouncementService'
import { FeatureAnnouncementModalHolder } from './FeatureAnnouncementModalHolder'
import { FeatureAnnouncementProvider } from './FeatureAnnouncementProvider'

interface FeatureAnnouncementModalProps {
  featureAnnouncement: FeatureAnnouncement
  projectSlug: string
  projectName: string
}

const FeatureAnnouncementModal: FC<FeatureAnnouncementModalProps> = ({
  featureAnnouncement,
  projectName,
  projectSlug,
}) => {
  const { query } = useRouter()
  const [announcementModalVariation, isClientReady] = useExperiment('announcement_modal_flag')
  const announcementFeatureEnabled = announcementModalVariation === 'on' && isClientReady

  const shouldShowForDeeplink = query.announce === featureAnnouncement.featureSlug
  const shouldShowForUser = shouldShowAnnouncementToUser(projectSlug)

  const shouldShowFeatureAnnouncement =
    featureAnnouncement && announcementFeatureEnabled && (shouldShowForDeeplink || shouldShowForUser)

  return shouldShowFeatureAnnouncement ? (
    <FeatureAnnouncementProvider
      featureAnnouncement={featureAnnouncement}
      projectSlug={projectSlug}
      projectName={projectName}
    >
      <FeatureAnnouncementModalHolder />
    </FeatureAnnouncementProvider>
  ) : null
}

export default FeatureAnnouncementModal
