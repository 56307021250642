import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { TheatricalRegions } from '@/contexts/TheatricalRegionContext/TheatricalRegionTypes'
import { getWebClient } from '@/services/ApolloClient'
import { TheatricalMovie } from '@/services/CmsService/TheatricReleases'
import { useGoal } from '@/services/GoalService'
import { Entry, Maybe, TheatricalRegion } from '@/types/codegen-contentful'
import { ReactFCC } from '@/types/react'
import { useLocale } from '@/utils/LocaleUtil'
import { getUserLocation } from '@/utils/location/location'
import { getStartDate, parseTheatricalDate } from '@/views/TicketCheckoutViews/ShowtimesView/utils/utils'

function isLastOfArray(element: number, array: number[]) {
  if (Array.isArray(array) && array.length > 0) {
    return element === array[array.length - 1]
  }
  return false
}

interface TheatricalRegionContextType {
  trailer: string | null
  horizontalPoster: string
  shouldShowTicketsComingSoon: boolean
  regionCode: string
  faqs: Maybe<Entry>[]
  pifLink: string
  ticketsLink: string
  freeTicketsLink: string
  hubPageHeroBackgroundVideo: string
  releaseDate: Date | null
  isWrongRegion: boolean
  redirectRegion: string | null
  redirectTicketsLink: string
  userCountry: string
  userCity: string | null
  userLocation: { latitude: number; longitude: number } | null
  goalLineInfo: {
    goalTitle: string | null
    ticketGoal: number
    costPerTicket: number
    ticketsSold: number
    currentMilestone: number
    totalGoalPercentage: number
    milestonePercentage: number
    isMilestoneText: boolean
    shouldShowGoalLine: boolean
  }
  regionCountries: string[]
  regionName: string
  isInTheaters: boolean
  flag: string | undefined
  currentTheatricalMovies: TheatricalMovie[] | null
  initialSearchDate: Date | null
  shouldCollectEmailsForTicketPage: boolean
  shouldCollectEmailsForFreeTicketPage: boolean
  slug: string | null
  isGroupTicketsEnabled: boolean
  initialExpressCheckoutOffer: string | null
  supportedTheatricalRegions: TheatricalRegion[]
  freeTicketRequestAmount: number
  allTheatricalReleaseRegions: TheatricalRegion[]
}

interface TheatricalRegionProviderProps {
  regionData: TheatricalRegion[] | []
  supportedTheatricalRegions: TheatricalRegion[] | []
  currentMovies?: TheatricalMovie[] | []
}

const TheatricalRegionContext = React.createContext<TheatricalRegionContextType>({
  trailer: '',
  horizontalPoster: '',
  shouldShowTicketsComingSoon: false,
  regionCode: TheatricalRegions.US,
  faqs: [],
  pifLink: '',
  ticketsLink: '',
  freeTicketsLink: '',
  hubPageHeroBackgroundVideo: '',
  releaseDate: null,
  isWrongRegion: false,
  redirectRegion: null,
  redirectTicketsLink: '',
  userCountry: '',
  userCity: null,
  userLocation: null,
  regionCountries: [],
  goalLineInfo: {
    goalTitle: null,
    ticketGoal: 0,
    costPerTicket: 0,
    ticketsSold: 0,
    currentMilestone: 0,
    totalGoalPercentage: 0,
    milestonePercentage: 0,
    isMilestoneText: true,
    shouldShowGoalLine: false,
  },
  regionName: 'United States',
  isInTheaters: true,
  flag: undefined,
  currentTheatricalMovies: null,
  initialSearchDate: null,
  shouldCollectEmailsForTicketPage: false,
  shouldCollectEmailsForFreeTicketPage: false,
  slug: null,
  isGroupTicketsEnabled: false,
  initialExpressCheckoutOffer: null,
  supportedTheatricalRegions: [],
  freeTicketRequestAmount: 1,
  allTheatricalReleaseRegions: [],
})

export const TheatricalRegionProvider: ReactFCC<TheatricalRegionProviderProps> = ({
  children,
  // TODO: rename public api to match
  regionData: allTheatricalReleaseRegions,
  supportedTheatricalRegions,
  currentMovies,
}) => {
  const [userCountry, setUserCountry] = useState('')
  const [userCity, setUserCity] = useState('')
  const [userLocation, setUserLocation] = useState<{ latitude: number; longitude: number } | null>(null)
  const { query } = useRouter()
  const { locale } = useLocale()
  const client = getWebClient({ locale })
  const country = query?.region
  useEffect(() => {
    getUserLocation().then((userLocation) => {
      setUserCity(userLocation?.city ?? '')
      setUserCountry(userLocation?.countryCode ?? '')
      if (userLocation?.coordinates) setUserLocation(userLocation?.coordinates)
    })
  }, [])

  const usRegion = useMemo(() => {
    return allTheatricalReleaseRegions?.find(({ region }) => region === 'US') ?? null
  }, [allTheatricalReleaseRegions])

  const otherRegion = useMemo(() => {
    return allTheatricalReleaseRegions?.find(({ region }) => region !== 'US') ?? null
  }, [allTheatricalReleaseRegions])

  const slug = useMemo(() => {
    if (otherRegion?.theatricalSlug) return otherRegion.theatricalSlug
    if (usRegion?.theatricalSlug) return usRegion.theatricalSlug
    return null
  }, [otherRegion, usRegion])

  const { raisedRaw } = useGoal({
    slug: slug ?? '',
    client,
  })

  const trailer = useMemo(() => {
    if (otherRegion?.trailerUrl) return otherRegion.trailerUrl
    if (usRegion?.trailerUrl) return usRegion.trailerUrl
    return null
  }, [otherRegion?.trailerUrl, usRegion?.trailerUrl])

  const horizontalPoster = useMemo(() => {
    if (otherRegion?.horizontalPosters?.length > 0) return otherRegion?.horizontalPosters[0]?.public_id
    if (usRegion?.horizontalPosters?.length > 0) return usRegion?.horizontalPosters[0]?.public_id
    return ''
  }, [otherRegion?.horizontalPosters, usRegion?.horizontalPosters])

  const isInTheaters = useMemo(() => {
    if (otherRegion?.hasTheatricalEnded) return !otherRegion?.hasTheatricalEnded
    if (usRegion?.hasTheatricalEnded) return !usRegion?.hasTheatricalEnded
    return true
  }, [otherRegion?.hasTheatricalEnded, usRegion?.hasTheatricalEnded])

  const shouldShowTicketsComingSoon = useMemo(() => {
    if (otherRegion?.showComingSoon) return otherRegion?.showComingSoon
    if (usRegion?.showComingSoon) return usRegion?.showComingSoon
    return false
  }, [otherRegion?.showComingSoon, usRegion?.showComingSoon])

  const regionCode = useMemo(() => {
    if (otherRegion?.region) return otherRegion?.region
    return 'US'
  }, [otherRegion?.region])

  const regionName = useMemo(() => {
    if (otherRegion?.name) return otherRegion?.name
    if (usRegion?.name) return usRegion?.name
    return 'United States'
  }, [otherRegion?.name, usRegion?.name])

  const faqs: Maybe<Entry>[] = useMemo(() => {
    if (otherRegion?.faQsCollection?.items) return otherRegion?.faQsCollection?.items
    if (usRegion?.faQsCollection?.items) return usRegion?.faQsCollection?.items
    return []
  }, [otherRegion?.faQsCollection?.items, usRegion?.faQsCollection?.items])

  const pifLink = useMemo(() => {
    if (country) return `/pay-it-forward/${slug}/${country}`
    if (otherRegion?.region) return `/pay-it-forward/${slug}/${otherRegion?.region}`
    return `/pay-it-forward/${slug}`
  }, [country, otherRegion?.region, slug])

  const ticketsLink = useMemo(() => {
    if (country) return `/tickets/${slug}/${country}`
    if (otherRegion?.region) return `/tickets/${slug}/${otherRegion?.region}`
    return `/tickets/${slug}`
  }, [country, otherRegion?.region, slug])

  const freeTicketsLink = useMemo(() => {
    if (country) return `/tickets/${slug}/${country}?promo=claim-free-ticket`
    if (otherRegion?.region) return `/tickets/${slug}/${otherRegion?.region}?promo=claim-free-ticket`
    return `/tickets/${slug}?promo=claim-free-ticket`
  }, [country, otherRegion?.region, slug])

  const hubPageHeroBackgroundVideo = useMemo(() => {
    if (otherRegion?.hubPageHeroBackgroundVideo) return otherRegion.hubPageHeroBackgroundVideo
    if (usRegion?.hubPageHeroBackgroundVideo) return usRegion.hubPageHeroBackgroundVideo
    return ''
  }, [otherRegion?.hubPageHeroBackgroundVideo, usRegion?.hubPageHeroBackgroundVideo])

  const releaseDate = useMemo(() => {
    if (otherRegion?.releaseDate) return getStartDate(parseTheatricalDate(otherRegion.releaseDate), new Date())
    if (usRegion?.releaseDate && regionCode === TheatricalRegions.US) {
      return getStartDate(parseTheatricalDate(usRegion.releaseDate), new Date())
    }
    return null
  }, [otherRegion?.releaseDate, regionCode, usRegion?.releaseDate])

  const initialSearchDate = useMemo(() => {
    if (otherRegion?.initialShowtimeSearchDate)
      return getStartDate(parseTheatricalDate(otherRegion.initialShowtimeSearchDate), new Date())
    if (usRegion?.initialShowtimeSearchDate && regionCode === TheatricalRegions.US) {
      return getStartDate(parseTheatricalDate(usRegion.initialShowtimeSearchDate), new Date())
    }
    return null
  }, [otherRegion?.initialShowtimeSearchDate, regionCode, usRegion?.initialShowtimeSearchDate])

  const isWrongRegion = useMemo(() => {
    const currentRegion = supportedTheatricalRegions?.find((r) => {
      return r.region === regionCode
    })

    return currentRegion?.countries
      ? !currentRegion?.countries?.some((r) => {
          return r?.toLowerCase() === userCountry.toLowerCase()
        })
      : false
  }, [regionCode, supportedTheatricalRegions, userCountry])

  const redirectRegion = useMemo(() => {
    const regionToRedirect = supportedTheatricalRegions?.find((r) => {
      return r?.countries ? r?.countries?.some((c) => c?.toLowerCase() === userCountry.toLowerCase()) : null
    })

    return regionToRedirect?.region ?? null
  }, [supportedTheatricalRegions, userCountry])

  const redirectTicketsLink = useMemo(() => {
    const regionToRedirect = supportedTheatricalRegions?.find((r) => {
      return r?.countries ? r?.countries?.some((c) => c?.toLowerCase() === userCountry.toLowerCase()) : null
    })

    const newCountry = regionToRedirect?.region?.toLowerCase() ?? null
    if (newCountry) return `/tickets/${slug}/${newCountry}`
    if (otherRegion?.region) return `/tickets/${slug}/${otherRegion?.region}`
    return `/tickets/${slug}`
  }, [otherRegion?.region, slug, supportedTheatricalRegions, userCountry])

  const shouldShowUsGoal = useMemo(() => {
    return regionCode === TheatricalRegions.US || !otherRegion?.enableRegionGoal
  }, [otherRegion?.enableRegionGoal, regionCode])

  const getPropertyFromRegion = useCallback(
    (regionCode: string, property: keyof TheatricalRegion, defaultVal: null | boolean | number) => {
      const value = shouldShowUsGoal ? usRegion?.[property] : otherRegion?.[property]
      if (value) return value
      return defaultVal
    },
    [shouldShowUsGoal, otherRegion, usRegion],
  )

  const goalLineInfo = useMemo(() => {
    const goalTitle = getPropertyFromRegion(regionCode, 'goalLineTitle', null)
    const ticketGoal = getPropertyFromRegion(regionCode, 'goalLineTicketGoal', 0)
    const costPerTicket = getPropertyFromRegion(regionCode, 'costPerTicket', 14)
    const ticketsSold = raisedRaw ? Math.round(raisedRaw / 100 / costPerTicket) : 0
    const shouldShowGoalLine = getPropertyFromRegion(regionCode, 'enableRegionGoal', false)
    const totalGoalPercentage = Math.floor((ticketsSold / ticketGoal) * 100)
    const currentOtherRegionGoalMilestone = otherRegion?.ticketMilestones?.ticketMilestones?.find(
      (m: number, index: number) => {
        if (ticketsSold === 0) return m
        return m > ticketsSold || otherRegion?.ticketMilestones?.ticketMilestones?.length - 1 === index
      },
    )
    const currentUsGoalMilestone = usRegion?.ticketMilestones?.ticketMilestones?.find((m: number, index: number) => {
      if (m > ticketsSold) return m
      if (usRegion?.ticketMilestones?.ticketMilestones?.length - 1 === index) return m
    })
    const currentMilestone = shouldShowUsGoal ? currentUsGoalMilestone ?? 0 : currentOtherRegionGoalMilestone ?? null
    const hasMilestonePercentage = ticketsSold === 0 && currentMilestone === 0
    const milestonePercentage = hasMilestonePercentage ? 0 : Math.floor((ticketsSold / currentMilestone) * 100)
    const isMilestoneText = () => {
      if (shouldShowUsGoal) {
        return !isLastOfArray(currentMilestone, usRegion?.ticketMilestones?.ticketMilestones)
      }
      return !isLastOfArray(currentMilestone, otherRegion?.ticketMilestones?.ticketMilestones)
    }
    return {
      goalTitle,
      ticketGoal,
      ticketsSold,
      totalGoalPercentage,
      milestonePercentage,
      costPerTicket,
      currentMilestone,
      isMilestoneText: isMilestoneText(),
      shouldShowGoalLine,
    }
  }, [
    getPropertyFromRegion,
    otherRegion?.ticketMilestones?.ticketMilestones,
    raisedRaw,
    regionCode,
    shouldShowUsGoal,
    usRegion?.ticketMilestones?.ticketMilestones,
  ])
  const regionCountries = useMemo(() => {
    const region = supportedTheatricalRegions?.find((r) => {
      return r?.region === regionCode
    })

    return (region?.countries as string[]) ?? []
  }, [regionCode, supportedTheatricalRegions])

  const flag = useMemo(() => {
    const country = query?.region as string
    if (otherRegion?.countries?.includes(country?.toUpperCase())) return country.toLowerCase()
    if (usRegion?.countries?.includes(country?.toUpperCase())) return country.toLowerCase()
    if (otherRegion && otherRegion?.defaultFlag) return otherRegion?.defaultFlag?.toLowerCase()
    if (usRegion && usRegion?.defaultFlag) return usRegion?.defaultFlag?.toLowerCase()
    return undefined
  }, [otherRegion, query?.region, usRegion])

  const shouldCollectEmailsForTicketPage = useMemo(() => {
    if (otherRegion?.shouldCollectEmailsForTicketPage != null) return otherRegion?.shouldCollectEmailsForTicketPage
    if (usRegion?.shouldCollectEmailsForTicketPage != null) return usRegion?.shouldCollectEmailsForTicketPage
    return false
  }, [otherRegion?.shouldCollectEmailsForTicketPage, usRegion?.shouldCollectEmailsForTicketPage])

  const shouldCollectEmailsForFreeTicketPage = useMemo(() => {
    if (otherRegion?.shouldCollectEmailsForFreeTicketPage != null)
      return otherRegion?.shouldCollectEmailsForFreeTicketPage
    if (usRegion?.shouldCollectEmailsForFreeTicketPage != null) return usRegion?.shouldCollectEmailsForFreeTicketPage
    return false
  }, [otherRegion?.shouldCollectEmailsForFreeTicketPage, usRegion?.shouldCollectEmailsForFreeTicketPage])

  const currentTheatricalMovies = useMemo(() => {
    if (currentMovies && currentMovies.length > 0) return currentMovies
    return null
  }, [currentMovies])

  const initialExpressCheckoutOffer = useMemo(() => {
    if (otherRegion?.expressCheckoutInitialPerkId) return otherRegion?.expressCheckoutInitialPerkId
    if (usRegion?.expressCheckoutInitialPerkId && !otherRegion) return usRegion?.expressCheckoutInitialPerkId
    return null
  }, [otherRegion, usRegion?.expressCheckoutInitialPerkId])

  const isGroupTicketsEnabled = useMemo(() => {
    if (otherRegion?.groupTicketsEnabled != null) return otherRegion?.groupTicketsEnabled
    if (usRegion?.groupTicketsEnabled != null) return usRegion?.groupTicketsEnabled
    return false
  }, [otherRegion?.groupTicketsEnabled, usRegion?.groupTicketsEnabled])

  const freeTicketRequestAmount = useMemo(() => {
    if (otherRegion?.freeTicketRequestAmount) return otherRegion?.freeTicketRequestAmount
    if (usRegion?.freeTicketRequestAmount && regionCode === TheatricalRegions.US)
      return usRegion?.freeTicketRequestAmount
    return 1
  }, [otherRegion?.freeTicketRequestAmount, regionCode, usRegion?.freeTicketRequestAmount])

  return (
    <TheatricalRegionContext.Provider
      value={{
        trailer,
        horizontalPoster,
        shouldShowTicketsComingSoon,
        regionCode,
        faqs,
        pifLink,
        ticketsLink,
        freeTicketsLink,
        hubPageHeroBackgroundVideo,
        releaseDate,
        isWrongRegion,
        redirectRegion,
        redirectTicketsLink,
        userCountry,
        userCity,
        userLocation,
        regionCountries,
        regionName,
        goalLineInfo,
        isInTheaters,
        flag,
        currentTheatricalMovies,
        initialSearchDate,
        shouldCollectEmailsForTicketPage,
        shouldCollectEmailsForFreeTicketPage,
        slug,
        isGroupTicketsEnabled,
        initialExpressCheckoutOffer,
        supportedTheatricalRegions,
        freeTicketRequestAmount,
        allTheatricalReleaseRegions,
      }}
    >
      {children}
    </TheatricalRegionContext.Provider>
  )
}

export function useTheatricalRegionContext() {
  return React.useContext(TheatricalRegionContext)
}
