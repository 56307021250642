import { gql as fragmentedGQL } from '@apollo/client'
import Bugsnag from '@bugsnag/js'
import { NextSeoPropsWithRequiredFields } from '@/layout/Seo'
import { getMediaMetaData } from '@/layout/Seo/SeoHelper'
import { getWebClient } from '@/services/ApolloClient'
import { projectMetaFragment } from '@/services/ProjectsService'
import { Story, StoryAuthor } from '@/services/StoriesService'
import { getDisplayCountry } from '@/utils/DisplayCountryUtil'
import { getBasePath } from '@/utils/sitemap/base'
import { storyFragment } from '@/views/WatchProjectView/CommunityView/StoriesList'
import { shareTitle } from '@/views/WatchProjectView/CommunityView/StoryCard'

const getProjectStoryQuery = fragmentedGQL`
  ${projectMetaFragment}
  ${storyFragment}
  query getProjectStory($slug: String!, $storyId: ID!, $did: String) {
    project(slug: $slug) {
      ...ProjectMeta
    }
    story(id: $storyId) {
      ...StoryFragment
    }
  }
`

type GetStoryParams = { slug: string; storyId: string; locale?: string }

export async function getStory({ slug, storyId, locale }: GetStoryParams) {
  let data

  try {
    const client = getWebClient({ locale })
    const projectStoryResult = await client.query({
      query: getProjectStoryQuery,
      variables: {
        slug,
        storyId,
        did: '',
      },
    })

    data = projectStoryResult.data
  } catch (e) {
    if (e instanceof Error) {
      Bugsnag.notify(e)
    }

    // eslint-disable-next-line no-console
    console.error(`class=StoriesService event=ErrorGettingProjectStory slug="${slug}" storyId="${storyId}"`, e)
  }

  return data
}

const getUserStory = fragmentedGQL`
${projectMetaFragment}
  query UserStory($slug: String!, $userStoryId: ID!) {
    project(slug: $slug) {
      ...ProjectMeta
    }
    userStory(id: $userStoryId) {
      user {
        firstName
        uuid
        country
        image
      }
      id
      authorLocation
      totalLikes
      text
      duration
      videoUri
      posterCloudinaryPath
      title
      momentId
      momentTimeCode
      subtitle
      isVideoStory
      videoGuid
      isLikedByUser
      isMomentStory
      isVip
      publishDate
    }
  }
`

type GetCollectibleStoryParams = { userStoryId: string; slug: string; locale?: string }

export async function getCollecibleStory({ locale, userStoryId, slug }: GetCollectibleStoryParams) {
  let data
  const client = getWebClient({ locale })

  try {
    const collectibleStoryResult = await client.query({
      query: getUserStory,
      variables: {
        userStoryId,
        slug,
      },
    })

    data = collectibleStoryResult.data
  } catch (e) {
    if (e instanceof Error) {
      Bugsnag.notify(e)
    }
  }

  return data
}

export function getAuthorDisplay({
  defaultViewerName,
  firstName,
  country,
  authorLocation,
  locale = 'en',
}: StoryAuthor & { locale?: string }): {
  viewerName: string
  fullDisplay: string
} {
  const viewerName = firstName || defaultViewerName
  const authorCountry = country || authorLocation
  let locationDisplay = ''

  if (authorCountry) {
    locationDisplay = ` from ${getDisplayCountry(authorCountry, locale)}`
  }

  return {
    viewerName,
    fullDisplay: viewerName + locationDisplay,
  }
}

export interface StorySeqParams {
  story: Story
  projectSlug: string
  projectName: string
  posterUrl: string
  locale?: string
}

export function getStorySEO({
  locale = 'en',
  projectName,
  posterUrl,
  projectSlug,
  story,
}: StorySeqParams): NextSeoPropsWithRequiredFields {
  const url = `${getBasePath(locale)}/watch/${projectSlug}/community/stories/${story?.id}`
  const { viewerName } = getAuthorDisplay({
    authorLocation: story?.authorLocation,
    country: story?.userProfile?.country,
    defaultViewerName: 'a viewer',
    firstName: story?.userProfile?.firstName,
    locale,
  })

  let title = shareTitle({ project: projectName, viewerName })
  let description = ''

  if (story?.video?.title) {
    title = story?.video?.title
  }

  if (story?.video) {
    description =
      story?.video?.subtitle || story?.video?.title || story?.video?.name || `Come and see the impact of ${name}`
  } else if (story?.thankYouNote) {
    description = story?.thankYouNote

    if (description.length > 160) {
      description = description.substring(0, 150) + '...'
    }
  }

  return getMediaMetaData({
    url,
    cloudinaryImagePath: story?.video?.posterCloudinaryPath || posterUrl,
    cloudinaryTransformation: 'c_pad,q_auto,b_auto',
    description,
    title: `${projectName} Story: ${story?.video?.title || viewerName} | Angel Studios`,
    openGraph: {
      title,
      url,
    },
    type: 'tv_show',
  })
}
