import React from 'react'
import classNames from 'classnames'
import { BasicCarousel } from '@/molecules/BasicCarousel'
import { DocumentCard } from '@/molecules/DocumentCard'
import { Downloadable } from '@/services/DownloadablesService'
import { useSafeTrack } from '@/utils/analytics'

interface DocumentScrollerProps {
  authenticated: boolean
  downloadables: Downloadable[]
  project: string
  returnTo: string
  wrapperClassName?: string
  padding?: boolean
}

export const DocumentScroller: React.FC<DocumentScrollerProps> = ({
  authenticated,
  downloadables,
  project,
  returnTo,
  padding = false,
}) => {
  const track = useSafeTrack()

  return (
    <BasicCarousel containerClassName={classNames({ 'px-4 sm:px-8 md:px-12 xl:px-16': padding })}>
      {downloadables.map((downloadable) => {
        const handleDownloadableClick = () => {
          track('Downloadable Clicked', { project_slug: project, title: downloadable.title, type: downloadable.type })
        }

        return (
          <DocumentCard
            authenticated={authenticated}
            key={downloadable.title}
            onClick={handleDownloadableClick}
            returnTo={returnTo}
            {...downloadable}
          />
        )
      })}
    </BasicCarousel>
  )
}
