import React from 'react'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { FaqAccordion } from '@/organisms/FaqAccordion'
import { AboutSectionContainer } from '@/views/WatchAboutView/AboutSectionContainer'

export const FaqSection: React.FC = () => {
  const { faqs } = useProjectMeta()

  return (
    <AboutSectionContainer id="faq">
      <FaqAccordion faqs={faqs} />
    </AboutSectionContainer>
  )
}
