import React from 'react'
import classNames from 'classnames'

interface EmptyStoryCardProps {
  className?: string
}

export const EmptyStoryCard: React.VFC<EmptyStoryCardProps> = (props) => {
  return (
    <div
      data-testid="empty-story-card"
      className={classNames(['bg-black rounded-lg p-8 mb-2 h-[232px]', props.className])}
    >
      <div className="flex flex-row">
        <div className="mr-4 h-[36px] w-[36px] rounded-[18px] bg-gray-400" />
        <div className="w-1/3 rounded-xl bg-gray-400/70" />
      </div>
    </div>
  )
}
